import React from 'react'
import DatePicker from 'react-datepicker';
import { incompleteCommunication } from '../../../containers/Admin/AdminActionCreator.component';
import { MSG_STRING } from './MessageTemplates.component'


const IncompleteApplication = ({ incompleteChange, incompleteAppStartDate, createLink, allStates,
    incompleteAppEndDate, getCount, sendCommunicationHandler, applicationCount, btnDisabled,
    communicateTypeHandler, msgBoxState, mailBoxSate, communicateType, incompleteApplicationData,
    incompleteApplication, mailTempleteClick }) => (
    <div className="container-fluid">
        <div className="row form-group">
            <div className="col-xs-12">
                <h3 className="text-center blue-text">Incomplete Application</h3>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Select Start Date</label>
                <DatePicker
                    value={incompleteApplication.StartDate}
                    // selected={incompleteAppStartDate}
                    className="date-picker form-control"
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select Date"
                    onChange={(e) => incompleteChange(e, 'startDate')}
                />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Select End Date</label>
                <DatePicker
                    value={incompleteApplication.EndDate}
                    // selected={incompleteAppEndDate}
                    className="date-picker form-control"
                    dateFormat="DD-MM-YYYY"
                    placeholderText="Select Date"
                    onChange={(e) => incompleteChange(e, 'endDate')}
                />
            </div>
            <div className="col-sm-4 col-xs-12">
                <label className="font-12px">Select State</label>
                <select className="form-control" id="phocketKnow" onChange={(e) => incompleteChange(e, 'stateFilter')}>
                    <optgroup className="display-none">
                        <option value="">Select State</option>
                    </optgroup>
                    <optgroup label="State">
                        {allStates.length > 0 ?
                            allStates.map((state, i) => {
                                return (
                                    <option key={i} value={state}>{state}</option>
                                )
                            })
                            : null}
                    </optgroup>
                </select>
            </div>
        </div>
        <div className="row form-group">
            <div className="col-xs-12">
                <button className="profile-save-btn center-block" id="" onClick={e => getCount()}>
                    <span className="fa fa-paper-plane-o"></span>&nbsp;&nbsp;Submit
                </button>
            </div>
        </div>
        {applicationCount != '' && applicationCount.length > 0 ?
            <div>
                <div className="row form-group">
                    <div className="col-xs-12">
                        <div className="incom-app">
                            <h4><span className="fa fa-lightbulb-o"></span>&nbsp;&nbsp;TOTAL INCOMPLETE APPLICATIONS - <span className="orange-text">{applicationCount.length}</span></h4>
                        </div>
                    </div>
                </div>

                {/* <div className="row form-group text-center">
                        <div className="col-xs-6">
                            <input className="phocket-checkbox" type="checkbox" id="incompleteSendMessage" onChange={(e) => incompleteChange(e, 'Communication')} />
                            <label htmlFor="incompleteSendMessage"><span></span></label>&nbsp;&nbsp;
                            <span><strong>Message</strong></span>
                        </div>
                        <div className="col-xs-6">
                            <input className="phocket-checkbox" type="checkbox" id="incompleteSendMail" onChange={(e) => incompleteChange(e, 'Communication')} />
                            <label htmlFor="incompleteSendMail"><span></span></label>&nbsp;&nbsp;
                            <span><strong>Mail</strong></span>
                        </div>
                    </div> */}
                <div className='row'>
                    {msgBoxState == true ?
                        <div className='col-sm-6 col-xs-12'>
                            <h3 className=" text-center blue-text" style={{ marginTop: '15px' }}><strong>Templates</strong></h3>
                            <ol>
                                <li>
                                    <input type="radio" value={MSG_STRING.REVIEW_ON_PLAYSTORE} name="communicationMail" onClick={e => mailTempleteClick(e, 'REVIEW')} /> &nbsp;&nbsp;
                                    <span><strong>REVIEW ON PLAYSTORE</strong></span>
                                </li>
                                {/* <li>
                    <input type="radio" value={MSG_STRING.MONTH_END_SAL_ADVANCE} name="communicationMail" onClick={e => mailTempleteClick(e, 'MONTHEND')} /> &nbsp;&nbsp;
                        <span><strong>MONTH END SAL ADVANCE</strong></span>
                </li>
                <li>
                    <input type="radio" value={MSG_STRING.BILL_PAY_SAL_ADVANCE} name="communicationMail" onClick={e => mailTempleteClick(e, 'BILLPAY')} /> &nbsp;&nbsp;
                        <span><strong>BILL PAY SAL ADVANCE</strong></span>
                </li> */}
                                <li>
                                    <input type="radio" value={MSG_STRING.CREDFIN_APP_TRY_KARO} name="communicationMail" onClick={e => mailTempleteClick(e, 'TRYCREDFIN')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin APP TRY KARO</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.JHATPAT_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'JHATPATLOAN')} /> &nbsp;&nbsp;
                                    <span><strong>Repeat Application</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC} name="communicationMail" onClick={e => mailTempleteClick(e, 'ADVANCESALARY')} /> &nbsp;&nbsp;
                                    <span><strong>ELIGIBLE FOR ADVANCE SAL</strong></span>
                                </li>

                                <li>
                                    <input type="radio" value={MSG_STRING.FESTIVE_SHOPPING_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'FESTIVESHOPING')} /> &nbsp;&nbsp;
                                    <span><strong>FESTIVE SHOPPING LOAN</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.LOAN_WITHIN_FEW_MINUTES} name="communicationMail" onClick={e => mailTempleteClick(e, 'FEWMINUTELOAN')} /> &nbsp;&nbsp;
                                    <span><strong>LOAN WITHIN FEW MINUTES</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.LOAN_WITHIN_5_MINUTES} name="communicationMail" onClick={e => mailTempleteClick(e, 'FIVEMINUTELOAN')} /> &nbsp;&nbsp;
                                    <span><strong>LOAN WITHIN 5 MINUTES</strong></span>
                                </li>
                                {/* <li>
                    <input type="radio" value={MSG_STRING.LAST_CHANCE_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'LASTCHANCELOAN')} /> &nbsp;&nbsp;
                        <span><strong>LAST CHANCE LOAN</strong></span>
                </li> */}
                                <li>
                                    <input type="radio" value={MSG_STRING.UPTO_200000_LOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'UPTO100000')} /> &nbsp;&nbsp;
                                    <span><strong>UPTO 100000 LOAN</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.URGENT_CASH} name="communicationMail" onClick={e => mailTempleteClick(e, 'URGENTCASH')} /> &nbsp;&nbsp;
                                    <span><strong>URGENT CASH</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.LOAN_WAITING} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOANWAITING')} /> &nbsp;&nbsp;
                                    <span><strong>LOAN WAITING</strong></span>
                                </li>

                                <li>
                                    <input type="radio" value={MSG_STRING.LOAN_AT_LOW_INTEREST} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOWINTERST')} /> &nbsp;&nbsp;
                                    <span><strong>LOAN AT LOW INTEREST</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.MONEY_AT_SPEED_OF_ROCKET} name="communicationMail" onClick={e => mailTempleteClick(e, 'SPEEDOFROCKET')} /> &nbsp;&nbsp;
                                    <span><strong>MONEY AT SPEED OF ROCKET</strong></span>
                                </li>

                                <li>
                                    <input type="radio" value={MSG_STRING.ADVANCE_SALARY_VIA_BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'BANKBAZAR')} /> &nbsp;&nbsp;
                                    <span><strong>ADVANCE SALARY VIA BANKBAZAAR</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'BANKBAZAAR')} /> &nbsp;&nbsp;
                                    <span><strong>BankBazaar</strong></span>
                                </li>

                                <li>
                                    <input type="radio" value={MSG_STRING.SALARY_12_MONTH} name="communicationMail" onClick={e => mailTempleteClick(e, 'SALARY_12_MONTH')} /> &nbsp;&nbsp;
                                    <span><strong>SALARY 12 MONTH</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.GET_A_CREDFIN_PL} name="communicationMail" onClick={e => mailTempleteClick(e, 'GET_A_CREDFIN_PL')} /> &nbsp;&nbsp;
                                    <span><strong>GET A Credfin PL</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.ADVANCE_SAL_BY_BUDDYLOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'ADVANCE_SAL_BY_BUDDYLOAN')} /> &nbsp;&nbsp;
                                    <span><strong>ADVANCE SAL BY BUDDYLOAN</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.PRE_QUALIFIED_BUDDY_LOAN_CREDFIN} name="communicationMail" onClick={e => mailTempleteClick(e, 'PRE_QUALIFIED_BUDDY_LOAN_CREDFIN')} /> &nbsp;&nbsp;
                                    <span><strong>PRE-QUALIFIED BUDDY LOAN Credfin</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CREDFIN_BANKBAZAAR} name="communicationMail" onClick={e => mailTempleteClick(e, 'CREDFIN_BANKBAZAAR')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin BANKBAZAAR</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.LOAN_FOR_15000_SALARY} name="communicationMail" onClick={e => mailTempleteClick(e, 'LOAN_FOR_15000_SALARY')} /> &nbsp;&nbsp;
                                    <span><strong>LOAN FOR 15000 SALARY</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CredFin_App_partnered_with_Anjraj_Talent} name="communicationMail" onClick={e => mailTempleteClick(e, 'CredFin_App_partnered_with_Anjraj_Talent')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin App partnered with Anjraj_Talent</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.Dear_Customer_Refer_your_friends} name="communicationMail" onClick={e => mailTempleteClick(e, 'Dear_Customer_Refer_your_friends')} /> &nbsp;&nbsp;
                                    <span><strong>Dear Customer Refer your friends</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CredFin_App_partnered_with_Genesis} name="communicationMail" onClick={e => mailTempleteClick(e, 'CredFin_App_partnered_with_Genesis')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin App partnered with Genesis</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CredFin_App_partnered_with_Hyrexpert} name="communicationMail" onClick={e => mailTempleteClick(e, 'CredFin_App_partnered_with_Hyrexpert')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin App partnered with Hyrexpert</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CredFin_App_partnered_with_Karyarth} name="communicationMail" onClick={e => mailTempleteClick(e, 'CredFin_App_partnered_with_Karyarth')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin App partnered with Karyarth</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.CredFin_App_partnered_with_SAM_Career} name="communicationMail" onClick={e => mailTempleteClick(e, 'CredFin_App_partnered_with_SAM_Career')} /> &nbsp;&nbsp;
                                    <span><strong>Credfin App partnered with SAM Career</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value={MSG_STRING.PHONEPARLOAN} name="communicationMail" onClick={e => mailTempleteClick(e, 'PHONEPARLOAN')} /> &nbsp;&nbsp;
                                    <span><strong>PHONEPARLOAN</strong></span>
                                </li>
                            </ol>
                        </div>
                        : ""}
                    <div className={msgBoxState == true ? 'col-sm-6 col-xs-12' : 'col-sm-12'}>
                        <div className="row">
                            <div className="col-xs-6">
                                <button className={msgBoxState ? "btn-active profile-save-btn center-block" : "profile-save-btn center-block"} onClick={e => communicateTypeHandler('msg')} id="msgBtn">Msg</button>
                            </div>
                            <div className="col-xs-6">
                                <button className={mailBoxSate ? "btn-active profile-save-btn center-block" : "profile-save-btn center-block"} onClick={e => communicateTypeHandler('mail')} id="mailBtn">Mail &amp; Notification</button>
                            </div>
                        </div>

                        <div className="row">
                            {msgBoxState ?
                                <div className="col-xs-12 form-group">
                                    <textarea className="form-control" rows="5" placeholder="Message..." onChange={(e) => communicateType(e, 'msgBody')} value={incompleteApplicationData.body} readOnly />
                                </div>
                                : null}
                            {mailBoxSate ?
                                <div className="col-xs-12">
                                    <input type="text" className="form-control form-group" placeholder="Mail..." onChange={(e) => communicateType(e, 'mailTitle')} value={incompleteApplicationData.mailTitle} />
                                    <div className="richText form-group" contentEditable="true" id="incompleteAppsMailBody" />
                                    <div className="display-flex form-group">
                                        <input type="text" className="form-control white-bg-transparent" placeholder="Link URL" onChange={e => communicateType(e, "url")} />
                                        <button className="profile-save-btn margin-0" id="submitDetail" onClick={e => createLink()}>
                                            <span className="fa fa-link"></span>
                                        </button>
                                    </div>
                                    <div className="row margin-0">
                                        <div className="col-sm-6 col-xs-12">
                                            <input className="phocket-checkbox" type="checkbox" id="byStatusSendNotification" value="Notification" onChange={(e) => communicateType(e, 'communicationType')} />
                                            <label htmlFor="byStatusSendNotification"><span></span></label>&nbsp;&nbsp;
                                            <span><strong>Notification</strong></span>
                                        </div>
                                        <div className="col-sm-6 col-xs-12">
                                            <input className="phocket-checkbox" type="checkbox" id="byStatusSendMail" value="Mail" onChange={(e) => communicateType(e, 'communicationType')} />
                                            <label htmlFor="byStatusSendMail"><span></span></label>&nbsp;&nbsp;
                                            <span><strong>Mail</strong></span>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <button className="profile-save-btn center-block" disabled={btnDisabled} id="bulkCommunicate" onClick={(e) => sendCommunicationHandler()}>
                                    <span className="fa fa-comments-o"></span>&nbsp;&nbsp;Communicate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : ""}

    </div>
)

export default IncompleteApplication;