import React, { useState, useEffect } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getEventDataByPartnerId, getUrlApi, saveAgreegatorDataApi } from "./actioncreatormarketing";
import { agreegatorValidation } from "./validation";
import Bootstrap from "./bootstrap5";
import Loader from '../../presentationals/Loader/Loader.component'
import PopUp from "../../presentationals/Popup/Successpopup.component";
import SidePopup from "./maketingComponents/screens/modals/sidepopup";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const AggregatorRegistration = ({ editdata,pageViewHandler }) => {
    const [agreegatorData, setagreegatorData] = useState({})
    const [errorMessage, setError] = useState({})
    const [urlToDisplay, setUrl] = useState('')
    const [loaderState, setLoader] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [popupState, setpopupState] = useState('')
    const [popupType, setpopupType] = useState('')

    const changeHandler = (e, type) => {
        let changeData = Object.assign({}, agreegatorData)
        if (type !== 'organizationContactNo') {
            changeData[type] = e.target.value
        } else {
            changeData[type] = e
        }
        setagreegatorData(changeData)

    }
    const saveData = () => {
        console.log(agreegatorData)
        let validationMsg = agreegatorValidation(agreegatorData)
        console.log(validationMsg)
        if (validationMsg.status) {
            setError({})
            setLoader(true)
            saveAgreegatorDataApi(agreegatorData, callback => {
                console.log(callback)
                if (callback === 'success') {
                    setLoader(false)
                    setpopupType('success')
                    setpopupStatus('Data Save Successfully !')
                    setpopupState(true)
                    removePopup()

                } else {
                    setLoader(false)
                    setpopupType('error')
                    setpopupStatus('Please try again later !')
                    setpopupState(true)
                    removePopup()
                }
            })
        } else {
            setError(validationMsg)
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setpopupState(false)
        }, 5000);
    }
    const getUrlByApi = (e) => {
        if (e && e.target.files) {
            setLoader(true)
            let type = e.target.files[0].type === 'application/pdf' ? 'pdf' : 'img'

            getUrlApi(e.target.files[0], e.target.files[0].name, type, callback => {
                if (callback) {
                    let changeData = Object.assign({}, agreegatorData)
                    changeData['uploadLogoUrl'] = callback
                    setagreegatorData(changeData)
                    setUrl(callback.includes('//test.credfin.money') ? callback.replace('//test.credfin.money', '//testbackend.credfin.money') : callback.replace('//admin.credfin.money', '//adminbackend.credfin.money'))
                    setLoader(false)
                }
            })
        }
    }
    const getData = () => {
        getEventDataByPartnerId(editdata.partnerId, callback => {
            console.log(callback)
            let changData = Object.assign({}, agreegatorData)
            changData.companyName = callback.companyName
            changData.contactPerson = callback.contactPerson
            changData.creatorMode = callback.creatorMode
            changData.date = callback.date
            changData.email = callback.email
            changData.id = callback.id
            changData.mobileNumber = callback.mobileNumber
            changData.organizationContactNo = callback.organizationContactNo
            changData.partnerId = callback.partnerId
            changData.type = callback.type
            changData.companyName = callback.companyName
            changData.url = callback.url
            changData.webAddress = callback.webAddress
            changData.status = callback.active,
                changData.marketingModule = callback.marketingModule

            setagreegatorData(changData)
        })
    }
    const closePopup = () => {
        setpopupState(false)
    }
    useEffect(() => {
        console.log(editdata, 'editdata')
        if (!editdata) {
            let changData = Object.assign({}, agreegatorData)
            changData.marketingModule = true
            setagreegatorData(changData)

        } else {
            getData()
        }
    }, [])

    return (
        <>

            {loaderState ? <Loader /> : ""}
            {/* <Bootstrap /> */}
            <div className="marketing-css container-fluid full-height" >
                {popupState ? <SidePopup popupType={popupType} msg={popupStatus} /> : ""}
                {/* <Bootstrap />  */}
                <div className="row " style={{ marginTop: '20px', marginLeft: '20px' }}>
                    <h4 className="mb-0" style={{display:'flex'}}><ArrowBackIosNewIcon onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer',fontSize:'19px' }} />&nbsp; <b onClick={(e) => pageViewHandler(e, 'landingPage')}>Aggregator Registration</b></h4>
                </div>
                <hr className="mb-5 mt-2" />
                <div className="collection-box ">
                    <div className="row">
                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                            <label className="form-label">Aggregator Type &nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <select value={agreegatorData.type} className="form-select" onChange={(e) => changeHandler(e, 'type')} >
                                <option value="" >Select Option</option>
                                <option value="Digital">Digital</option>
                                <option value="ThirdParty">Third Party</option>
                                <option value="AmbassadorScouts">Ambassador / Scouts</option>
                                <option value="Corporate">Corporate</option>
                            </select>
                            {errorMessage && errorMessage.field === 'type' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                        </div>
                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                            <label className="form-label">Aggregator Name&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" value={agreegatorData.companyName} onChange={(e) => changeHandler(e, 'companyName')} className="form-control" placeholder="Enter Name" />
                            {errorMessage && errorMessage.field === 'companyName' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                        </div>
                        <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                            <label className="form-label">Organisation Contact Number&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <PhoneInput
                            
                                value={agreegatorData.organizationContactNo}
                                placeholder="Enter phone number"
                                className="form-control phone-input"
                                onChange={(e) => changeHandler(e, 'organizationContactNo')}
                            />
                            {errorMessage && errorMessage.field === 'organizationContactNo' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                            {/* <input type="number" className="form-control phone-input" placeholder="Enter Number" /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12" style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">POC Name&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input value={agreegatorData.contactPerson} type="text" onChange={(e) => changeHandler(e, 'contactPerson')} className="form-control" placeholder="Enter POC Name" />
                            {errorMessage && errorMessage.field === 'contactPerson' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                        </div>
                        <div className="col-sm-4 col-xs-12" style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">POC Email&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input value={agreegatorData.email} type="text" onChange={(e) => changeHandler(e, 'email')} className="form-control email-input" placeholder="Enter POC Email" />
                            {errorMessage && errorMessage.field === 'email' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                        </div>
                        <div className="col-sm-4 col-xs-12" style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">POC Mobile Number&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <input type="number" value={agreegatorData.mobileNumber} onChange={(e) => changeHandler(e, 'mobileNumber')} className="form-control phone-input" placeholder="Enter POC Mobile Number" />
                            {errorMessage && errorMessage.field === 'mobileNumber' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12" style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">Website Link</label>
                            <input type="text" value={agreegatorData.webAddress} onChange={(e) => changeHandler(e, 'webAddress')} className="form-control link-input" placeholder="Enter Website Link" />
                            {/* {errorMessage && errorMessage.field === 'url' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""} */}

                        </div>
                        <div className="col-sm-4 col-xs-12" style={{ marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">Creator Mode&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <select className="form-select" value={agreegatorData.creatorMode} onChange={(e) => changeHandler(e, 'creatorMode')}>
                                <option value="" >Select Option</option>
                                <option value="Branch">Branch</option>
                                <option value="System Integration">System Integration</option>
                                <option value="API/ javaScript">API/ javaScript</option>
                                <option value="Social Media">Social Media</option>
                            </select>
                            {errorMessage && errorMessage.field === 'creatorMode' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}
                        </div>
                        <div className="col-sm-4 col-xs-12" style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <label className="form-label">Status&nbsp;<span style={{ color: 'red' }}>*</span></label>
                            <select className="form-select" value={agreegatorData.status} onChange={(e) => changeHandler(e, 'status')}>
                                <option value="" >Select Option</option>
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                            </select>
                            {errorMessage && errorMessage.field === 'status' ? <span style={{ color: 'red' }}>{errorMessage.msg}</span> : ""}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 col-xs-12" style={{ marginTop:window.innerWidth > 1800 ? '40px': '20px' }}>
                            <div className="upload-main">
                                <div className="image-upload-div" >
                                    <input

                                        onChange={(e) => getUrlByApi(e)}
                                        accept={['.png', '.jpeg', '.jpg']}
                                        className="file-upload-input" type='file' />
                                    <div className="drag-text-new">
                                        <h5 className="upload-icon"> Click here to Upload Logo </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {urlToDisplay !== '' ?
                            <div className="col-sm-4 col-xs-12 text-center">
                                {console.log(urlToDisplay, 'urlToDisplay')}
                                <img src={urlToDisplay} style={{ maxWidth: '130px', marginTop: '20px' }} />
                            </div>
                            : ""}
                    </div>
                    <div className="row " style={{  marginTop:window.innerWidth > 1800 ? '40px': '20px', marginLeft: '10px' }}>
                        <button type="submit" className="btn submit" onClick={() => saveData()} >Save</button>
                    </div>
                    <p>&nbsp;</p>
                </div>
                <p>&nbsp;</p>




            </div >
        </>
    )

}
export default AggregatorRegistration