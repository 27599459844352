import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: Cheque Bounced

const ChequeBounced = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>This is to inform you that your cheque has bounced due to insufficient balance.</div>
        <br />
        <div>Cheque bounce law have been made very stringent by Government of India and it now attracts hefty penalties and prosecution up-to 2 years.</div>
        <br />
        <div>Get in touch with us on urgent basis.</div>
        <br />
        <div>Please deposit pending money ASAP.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Credfin</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default ChequeBounced