import React from 'react'
import { uploadedStampDuty, uploadedStampDutyCount } from '../../Admin/AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import Ui from '../../../presentationals/Admin/Utility/viewUploadedStamDuty.component'
import Popup from './stampPopup.component'
import SidePopup from '../../../presentationals/Popup/Popup.component'
class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stampDutyData: '',
            usedStatus: 'false',  
            pageNo: 1,
            noOfEntry: 10,
            loaderState: false,
            count: '',
            stampPopup: false,
            stampUrl: '',
            popupState: false,
            popupStatus: '',
            selectedFilter:'',
            useStatus:'false',
            initiateEsign:'false'
        }
    }
    close() {
        this.setState({ stampPopup: false })
    }
    getAllData() {
        this.setState({ loaderState: true })
        uploadedStampDuty(this.state.usedStatus, this.state.pageNo, this.state.noOfEntry,this.state.initiateEsign, callback => {
            this.setState({ stampDutyData: callback, loaderState: false })
        })
        this.getCount()
    }
    getCount() {
        this.setState({ loaderState: true })
        uploadedStampDutyCount(this.state.usedStatus, this.state.initiateEsign,callback => {
            this.setState({ count: callback, loaderState: false })
        })
    }
    componentDidMount() {
        this.getAllData()
    }
    usedHandler(e) {
        this.setState({ usedStatus: e.target.value }, () => this.getAllData())
    }
    paginationHandler(type) {
        if (type === 'left') {
            if (this.state.pageNo > 1) {
                this.setState({ pageNo: this.state.pageNo - 1 }, () => this.getAllData())
            }
        }
        if (type === 'right') {
            if (this.state.pageNo !== (this.state.count / 10)) {
                this.setState({ pageNo: this.state.pageNo + 1 }, () => this.getAllData())
            } else {
                this.setState({ popupState: true, popupStatus: 'you are already on last page!' }, this.removePopup())
            }
        }
    }
    openStampPopup(data) {
        this.setState({loaderState:true})
      let url=  data.replace(
            '//admin.credfin.money',
            '//admin.credfin.money'
          )
        this.setState({ stampUrl: url, stampPopup: true },()=>this.setState({loaderState:false}))
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false })
        }.bind(this), 5000)
    }
    initiateEsignhhandler(e){
        this.setState({initiateEsign:e.target.value},()=>this.getAllData())
        
    }

    render() {
//         let filterData = this.state.stampDutyData
//         if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
//             filterData = filterData.filter((row) => {
//                 console.log(row.initiateEsign)
// // if(row.initiateEsign){
//     return JSON.stringify(row.initiateEsign).includes(this.state.selectedFilter)

// // }       
         
//             })
//         }
        return (
            <div>
                {this.state.loaderState ?
                    <Loader />
                    : ""}


                <Ui
                usedStatus={this.state.usedStatus}
                initiateEsignhhandler={this.initiateEsignhhandler.bind(this)}
                selectedFilter={this.state.initiateEsign}
                    pageNo={this.state.pageNo}
                    openStampPopup={this.openStampPopup.bind(this)}
                    paginationHandler={this.paginationHandler.bind(this)}
                    count={this.state.count}
                    usedHandler={this.usedHandler.bind(this)}
                    stampDutyData={this.state.stampDutyData} />
                {this.state.stampPopup ?
                    <Popup close={this.close.bind(this)}
                        url={this.state.stampUrl}
                    />
                    : ""}
                {this.state.popupState ?
                    <SidePopup popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)}
                    />
                    : ""}
            </div>
        )
    }
}
export default View