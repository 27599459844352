import React from "react";
import CommunicationLogs from "./communicationLogsNew";
import RecoveryLogs from "../Recovery/RecoveryComLogs.container";
import ActivityStep from './activitySteps'
class LogsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewTab: 'activity'
        }
    }
    buttonHandle(data) {
        this.setState({ viewTab: data })
    }
    render() {
        const { allDetail } = this.props
        return (
            <div className="container-fluid" style={{marginTop:'80px'}}>
                <div className="col-sm-12 btn-group btn-grp-own-logs" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', }}>
                    <button className="btn btn-outline-primary" style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', background: this.state.viewTab === 'communication' ? '#006c67' : '#F1F7FF', color: this.state.viewTab === 'communication' ? '#fff' : '#000',zIndex:0 }} onClick={() => this.buttonHandle('communication')}> Communication Logs </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'recovery' ? '#006c67' : '#F1F7FF', color: this.state.viewTab === 'recovery' ? '#fff' : '#000',zIndex:0 }} onClick={() => this.buttonHandle('recovery')} >Recovery Logs </button>
                    <button className="btn btn-outline-primary" style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px', background: this.state.viewTab === 'activity' ? '#006c67' : '#F1F7FF', color: this.state.viewTab === 'activity' ? '#fff' : '#000',zIndex:0 }} onClick={() => this.buttonHandle('activity')} >All Activities </button>
                </div>
                {this.state.viewTab === 'communication' ?
                    <CommunicationLogs allDetail={allDetail} />
                    : ""}
                {this.state.viewTab === 'recovery' ?
                    <RecoveryLogs  allDetail={allDetail}/>
                    : ""}
                    {this.state.viewTab==='activity'?
                  <ActivityStep allDetail={allDetail}/>  
                :""}
            </div>
        )
    }
}
export default LogsMain