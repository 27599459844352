import React, { useEffect,useState } from "react";
import { getAllOrganisation } from "./actionCreator";
import noDataimg from '../../../../images/nodata.png' ;
const AllMerchnats = () => {
    const [data,setData] =useState([])
    useEffect(()=>{
        getAllOrganisation(callback=>{
            console.log(callback)
            if(callback && callback?.status === 'success' && callback?.data?.length > 0){
                setData(callback.data)
            }else{
                setData([])
            }
        })
    },[])
    return (
        <div className="container-fluid">
            {
                data && data?.length > 0 ?
            <div className="phocket-table-new">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            :
            <div className="text-center" style={{marginTop:'30px'}}>
            <img src={noDataimg}/>
            </div>
            }
        </div>
    )
}
export default AllMerchnats