const MSG_CONTENT = {

    EASY_LOANS: 'CredFin makes loans easy! Enjoy instant access with Preapproved Loans. Download now: https://bit.ly/3Iyih3h',
    SAY_GOODBYE: 'Say goodbye to financial worries with CredFin! Get quick Personal Loans with our app: https://bit.ly/3Iyih3h',
    ENJOYING_CREDFIN_FEEDBACK: 'Enjoying CredFin? Share your experience on Play Store and get a chance to win fantastic prizes! Your feedback matters. Download the app: https://bit.ly/3Iyih3h',
    PRE_APPROVED_LOANS: "CredFin's Pre-approved Loans: Easy loans with special processing fee discounts. Download now: https://bit.ly/3Iyih3h",
    FINANCIAL_SOLUTION: 'Your financial solution starts here with CredFin! Get Personal Loans on-the-go. Download now: https://bit.ly/3Iyih3h',
    NEED_PERSONAL_LOAN: 'Need a Personal loan? CredFin has you covered! Get instant approval via our app: https://bit.ly/3Iyih3h',
    URGENT_FUNDS: 'Need funds urgently? Get a personal loan up to 2 lakh quickly and easily with CredFin. Apply now: https://bit.ly/3Iyih3h',
    PAYDAY_BILLS: 'Hello there! Struggling with bills before payday? Get an advance salary loan with the lowest interest and fees with CredFin. Apply now: https://bit.ly/3Iyih3h',
    INSTANT_APPROVAL: "Instant approval guaranteed! Experience the speed of CredFin's approval process and get your funds within minutes. Apply now! Download App",
    PAY_LOAN_EMI: ` Hi, Your Credfin Loan EMI is due in {#var#} days and the repayment amount is INR {#var#}.
    Pay on time to improve your credit score for better loans in future.`,
    EMI_DUE_WITH_DATE_MANUAL_PAY: ` Hi, Your CredFin  Loan EMI is due on {#var#} and the repayment amount is INR {#var#}.
    Pay on time to improve your credit score for better loans in future.`,
    MISSING_DOCUMENT: " CREDFIN LOAN: We have noticed that your loan application process is not complete yet and is missing a few documents.Check your mail for list of missing docs.",
    LOAN_DUE_AUTO_DEBIT: "  Your CredFin Loan is due on {#var#}. The repayment amount is INR {#var#}.  Maintain sufficient balance to improve your credit score for better loans in future.",
    OTP_FOR_MOBILE_VERIFICATION: "Your Credfin verification code is {#var#}",
    EMI_DUE_WITH_DATE_AUTO_DEBIT: "Hi, Your CredFin  Loan EMI is due on {#var#}. The repayment amount is INR {#var#}.Maintain sufficient balance to improve your credit score for better loans in future.",
    PAY_LOAN: " Your CredFin  Loan is due on {#var#} and the repayment amount is INR {#var#}. Pay on time to improve your credit score for better loans in future.",
    NO_RESPONSE_FOR_LOAN_PROCESS: "CREDFIN LOAN: We tried reaching you but there was no response from your end.Kindly revert back for further processing of your loan application.",
    AUTO_DEBIT_LOAN_EMI: " Hi, Your Credfin Loan EMI is due in {#var#} days. The repayment amount is INR {#var#}.Maintain sufficient balance to improve your credit score for better loans in future"

}
const TemplateName = {
    EASY_LOANS: 'EASY LOANS',
    SAY_GOODBYE: 'SAY GOODBYE',
    ENJOYING_CREDFIN_FEEDBACK: 'ENJOYING CREDFIN FEEDBACK',
    PRE_APPROVED_LOANS: 'PRE APPROVED LOANS',
    FINANCIAL_SOLUTION: 'FINANCIAL SOLUTION',
    NEED_PERSONAL_LOAN: 'NEED PERSONAL LOAN',
    URGENT_FUNDS: 'URGENT FUNDS',
    PAYDAY_BILLS: 'PAYDAY BILLS',
    INSTANT_APPROVAL: 'INSTANT APPROVAL',
    PAY_LOAN_EMI: 'PAY LOAN EMI',
    EMI_DUE_WITH_DATE_MANUAL_PAY: 'EMI DUE WITH DATE & MANUAL PAY',
    MISSING_DOCUMENT: 'MISSING DOCUMENT',
    LOAN_DUE_AUTO_DEBIT: 'LOAN DUE & AUTO DEBIT',
    OTP_FOR_MOBILE_VERIFICATION: 'OTP FOR MOBILE VERIFICATION',
    EMI_DUE_WITH_DATE_AUTO_DEBIT: 'EMI DUE WITH DATE & AUTO DEBIT',
    PAY_LOAN: 'PAY LOAN',
    NO_RESPONSE_FOR_LOAN_PROCESS: 'NO RESPONSE FOR LOAN PROCESS',
    AUTO_DEBIT_LOAN_EMI: 'AUTO DEBIT LOAN EMI'
}

const TemplateIdPromotional = {

    EASY_LOANS: '1207171040213001175',
    SAY_GOODBYE: '1207171040423988133',
    ENJOYING_CREDFIN_FEEDBACK: '1207171041803909236',
    PRE_APPROVED_LOANS: '1207171040125617395',
    FINANCIAL_SOLUTION: '1207171040833518404',
    NEED_PERSONAL_LOAN: '1207171040468765065',
    URGENT_FUNDS: '1207171041077453408',
    PAYDAY_BILLS: '1207171041283326470',
    INSTANT_APPROVAL: '1207171041902017754',
   

}
const TemplateIdServiceExplicit = {

    PAY_LOAN_EMI: '1207170244562248337',
    EMI_DUE_WITH_DATE_MANUAL_PAY: '1207170244748319830',
    MISSING_DOCUMENT: '1207170176946079114',
    LOAN_DUE_AUTO_DEBIT: '1207170244650127104',
    OTP_FOR_MOBILE_VERIFICATION: '1207170244811130509',
    EMI_DUE_WITH_DATE_AUTO_DEBIT: '1207170244961062084',
    PAY_LOAN: '1207170244228802501',
    NO_RESPONSE_FOR_LOAN_PROCESS: '1207170176622928601',
    AUTO_DEBIT_LOAN_EMI: '1207170244716975980'

}
const TemplateType = {

    EASY_LOANS: 'Promotional',
    SAY_GOODBYE: 'Promotional',
    ENJOYING_CREDFIN_FEEDBACK: 'Promotional',
    PRE_APPROVED_LOANS: 'Promotional',
    FINANCIAL_SOLUTION: 'Promotional',
    NEED_PERSONAL_LOAN: 'Promotional',
    URGENT_FUNDS: 'Promotional',
    PAYDAY_BILLS: 'Promotional',
    INSTANT_APPROVAL: 'Promotional',
    PAY_LOAN_EMI: 'Service Implicit',
    EMI_DUE_WITH_DATE_MANUAL_PAY: 'Service Implicit',
    MISSING_DOCUMENT: 'Service Implicit',
    LOAN_DUE_AUTO_DEBIT: 'Service Implicit',
    OTP_FOR_MOBILE_VERIFICATION: 'Service Implicit',
    EMI_DUE_WITH_DATE_AUTO_DEBIT: 'Service Implicit',
    PAY_LOAN: 'Service Implicit',
    NO_RESPONSE_FOR_LOAN_PROCESS: 'Service Implicit',
    AUTO_DEBIT_LOAN_EMI: 'Service Implicit'


}
const TemplateHeader = {

    EASY_LOANS: '151999',
    SAY_GOODBYE: '151999',
    ENJOYING_CREDFIN_FEEDBACK: '151999',
    PRE_APPROVED_LOANS: '151999',
    FINANCIAL_SOLUTION: '151999',
    NEED_PERSONAL_LOAN: '151999',
    URGENT_FUNDS: '151999',
    PAYDAY_BILLS: '151999',
    INSTANT_APPROVAL: '151999',
    PAY_LOAN_EMI: 'CFNSPL',
    EMI_DUE_WITH_DATE_MANUAL_PAY: 'CFNSPL',
    MISSING_DOCUMENT: 'CFNSPL',
    LOAN_DUE_AUTO_DEBIT: 'CFNSPL',
    OTP_FOR_MOBILE_VERIFICATION: 'CFNSPL',
    EMI_DUE_WITH_DATE_AUTO_DEBIT: 'CFNSPL',
    PAY_LOAN: 'CFNSPL',
    NO_RESPONSE_FOR_LOAN_PROCESS: 'CFNSPL',
    AUTO_DEBIT_LOAN_EMI: 'CFNSPL'


}
export {
    MSG_CONTENT,
    TemplateIdPromotional,
    TemplateIdServiceExplicit,
    TemplateType,
    TemplateHeader,
    TemplateName
};
