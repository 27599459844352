import React, { useState } from "react";
import Listing from "./agreegatorlisting";
import BudgetPlan from "../../budgetPlan";
import AggregatorRegistration from "../../registrationForm";
import ConfigPage from "./components/ConfigMainTabs";
const TabsOfAgreegator = ({ admin, setmargin, pageViewHandler }) => {
    const [viewState, setViewState] = useState('list')
    return (
        <>

            <div className="row fixed-top" style={{ marginTop: setmargin?'70px':'' }}>
                <div className="col-sm-6 col-xs-6" onClick={() => setViewState('list')} style={{ borderBottom:viewState === 'configuration' ?'2px solid #006c67':'',background: viewState === 'list' ? '#006c67' : '#DBE9FF', padding: viewState==='list'?'13px':'12.2px', textAlign: 'center', color: viewState === 'list' ? '#fff' : '#000', cursor: 'pointer' }}>
                    <i class="fa fa-list" aria-hidden="true"></i>&nbsp; Aggregators List

                </div>
                <div className="col-sm-6 col-xs-6" onClick={() => setViewState('configuration')} style={{borderBottom:viewState === 'list' ?'2px solid #006c67':'', background: viewState === 'configuration' ? '#006c67' : '#DBE9FF', padding: viewState==='configuration'?'13px':'12.2px', textAlign: 'center', color: viewState === 'configuration' ? '#fff' : '#000', cursor: 'pointer' }}>
                    <i class="fa fa-user-plus" aria-hidden="true"></i>&nbsp;  Add New Aggregator

                </div>

            </div>
            
            <div className="full-height">
                {viewState === 'list' ?
                    <Listing setViewState={setViewState} admin={admin} pageViewHandler={pageViewHandler}/>
                    : ""}
                {viewState === 'configuration' ?
                    <ConfigPage setViewState={setViewState} pageViewHandler={pageViewHandler}/>
                    : ""}
            </div>
            {/* //     : ""}
            // {viewState === 'add' ? */}
            {/* //     <AggregatorRegistration />
            //     : ""}
            // {viewState === 'budget' ?
            //     <BudgetPlan />
            //     : ""} */}

        </>
    )
}
export default TabsOfAgreegator