import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CibilPage from './UserCibil.container'
import ProfileAnalysis from '../CreditModule/mainTab'
import ProfileAnalysisOld from '../../Admin/CustomerPages/ProfileAnalysisNew.container'
import BasicPage from './UserBasicNew'
import FinancePage from './LoanReportsNew'
import DocsPage from './UserDocs.container'
import ActionPage from './Action.container'
import MessagePage from './Communication.container'
import TransactionEmiPage from './TransactionEmi.container'
import EmiHistoryUi from '../Emi/EMIHistory.container'
import Feedback from './UserFeedbackLogs.container'
import Agreement from './UserAgreement.container'
import StatusActivity from './UserStatusActivity.container'
import EditProfile from './EditUserDetails.container'
import FieldBoyUi from './AssignFieldBoy.container'
import Emi from '../Emi/UserEmi.Component'
import ContactsApps from './UserMobileContacts.container'
import { APIS } from '../../../../utils/api-factory'
import LogsMainPage from './LogsMainPage.js'
import Operations from './operationsJourney.js'
import APITracker from './APITracker'
class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rawMsgData: [],
            msgData: '',
            loadarState: false,
            deviceData: '',
            deviceDataLoader: false,
            dataChange: this.props.dataChange,
            
        }
        this.topRef = React.createRef()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.userVisiblePanel !== this.props.userVisiblePanel && this.props.userVisiblePanel === 7) {
            this.topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        const { userVisiblePanel } = this.props;
        return (
            <div className="row height-81" >
                <div className="col-xs-12 no-padding full-height" style={{ background: userVisiblePanel === 2 ? '#fff' : '#fff',minHeight: '800px'}} ref={this.topRef}>
                    {this.userActiveView(userVisiblePanel)}
                </div>
            </div>
        )
    }

    componentDidMount() {
        // this.props.getData('2')
        this.setState({ dataChange: this.props.dataChange })
        const { allDetail } = this.props;
        // this.setState({ loadarState: true, deviceDataLoader: true })
        // fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
        //     .then(res => res.json())
        //     .then(json => {
        //         this.setState({
        //             deviceData: json,
        //             deviceDataLoader: false
        //         })
        //     })
        // if (allDetail != null && allDetail.appData != null && typeof allDetail.appData.deviceSMS === 'string' && allDetail.appData.deviceSMS != '') {
        //     this.setState({ rawMsgData: JSON.parse(allDetail.appData.deviceSMS) },
        //      () => {
        //          if(this.state.rawMsgData.length > 0){
        //             fetch(APIS.USER_MSG_ANALYSIS + allDetail.userId)
        //             .then(json => json.json())
        //             .then(res => {
        //                 this.setState({ msgData: res, loadarState: false })
        //             })
        //          } else {
        //             this.setState({ loadarState: false })
        //         }
        //      })
        // }
    }

    userActiveView(userVisiblePanel) {
        const { allStatus, allDetail, userDetails, admin, userDetail, creditScoringPopup, markCompleteBtn } = this.props;

        switch (userVisiblePanel) {
            case 0: return <BasicPage
                UserDetailTabsHandler={this.props.UserDetailTabsHandler}
                allStatus={allStatus}
                userDetailHandler={this.props.userDetailHandler}
                getData={this.props.getData.bind(this)}
                allDetail={allDetail}
                userDetail={userDetails}
                showCredit={this.state.loadarState}
                getCreditPopupState={this.getCreditPopupState.bind(this)}
                admin={admin}
                markCompleteBtn={markCompleteBtn}
            />;
            case 1: return <FinancePage getData={this.props.getData.bind(this)} allDetail={allDetail} userDetail={userDetails} admin={admin} />;

            case 2: return <ProfileAnalysis
                allDetail={allDetail}
                userDetail={userDetails}
                loadarState={this.state.loadarState}
                msgData={this.state.msgData}
                deviceData={this.state.deviceData}
                admin={admin}
                deviceDataLoader={this.state.deviceDataLoader}
            />;
            // case 3: return <LogsMainPage allDetail={allDetail} userDetail={userDetails} />
            // case 4: return <Operations userDetail={userDetails} allDetail={allDetail} admin={admin} markCompleteBtn={markCompleteBtn} />;
            // case 5: return <ContactsApps
            //     allDetail={allDetail}
            //     admin={admin}
            //     deviceData={this.state.deviceData}
            //     deviceDataLoader={this.state.deviceDataLoader}
            // />
            // case 6: return <ProfileAnalysisOld
            //     allDetail={allDetail}
            //     userDetail={userDetails}
            //     loadarState={this.state.loadarState}
            //     msgData={this.state.msgData}
            //     deviceData={this.state.deviceData}
            //     admin={admin}
            //     deviceDataLoader={this.state.deviceDataLoader}
            // />
            case 3: return <LogsMainPage allDetail={allDetail} userDetail={userDetails} />
            case 4: return <Operations userDetail={userDetails} allDetail={allDetail} admin={admin} markCompleteBtn={markCompleteBtn} />;
            case 5: return <ContactsApps
                allDetail={allDetail}
                admin={admin}
                deviceData={this.state.deviceData}
                deviceDataLoader={this.state.deviceDataLoader}
            />
            case 6: return <ProfileAnalysisOld
                allDetail={allDetail}
                userDetail={userDetails}
                loadarState={this.state.loadarState}
                msgData={this.state.msgData}
                deviceData={this.state.deviceData}
                admin={admin}
                deviceDataLoader={this.state.deviceDataLoader}
            />
            case 7: return <APITracker allDetail={allDetail} userDetail={userDetails} />
            // case 2: return <CibilPage allDetail={allDetail} />;
            // case 3: return <FinancePage  getData={this.props.getData.bind(this)} allDetail={allDetail} userDetail={userDetails} admin={admin} />;
            // case 4: return <DocsPage allDetail={allDetail} admin={admin} markCompleteBtn={markCompleteBtn} />;
            // case 5: return <ActionPage getData={this.props.getData.bind(this)} allDetail={allDetail} userDetails={userDetail} userDetail={userDetails} admin={admin} />;
            // case 6: return <MessagePage getData={this.props.getData.bind(this)} allDetail={allDetail} userDetail={userDetails} admin={admin} creditScoringPopup={creditScoringPopup} />;
            // case 7: return <TransactionEmiPage allDetail={allDetail} userDetail={userDetails} admin={admin} />;
            // case 8: return <Feedback allDetail={allDetail} userDetail={userDetails} />
            // case 9: return <Agreement allDetail={allDetail} userDetail={userDetails} />
            // case 10: return <EditProfile getData={this.props.getData.bind(this)}  allDetail={allDetail} userDetails={userDetails} admin={admin} />
            // case 11: return <FieldBoyUi allDetail={allDetail} admin={admin} />
            // case 12: return <Emi userDetail={userDetails}  allDetail={allDetail} admin={admin} />
            // case 13: return <EmiHistoryUi userDetail={userDetails} allDetail={allDetail} admin={admin} />
            // case 14: return <ContactsApps
            //     allDetail={allDetail}
            //     admin={admin}
            //     deviceData={this.state.deviceData}
            //     deviceDataLoader={this.state.deviceDataLoader}
            // />
            default: return;
        }
    }

    getCreditPopupState(state) {
        this.props.getCreditPopupState(state)
    }

    componentWillUnmount() {

    }
}


const mapStateToProps = ({ CRMMenu, allUserDetails }) => {
    return {
        userVisiblePanel: CRMMenu.UserOptions.selectedNavProp,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);