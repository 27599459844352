import React from 'react'
import Ui from '../../../presentationals/Admin/BulkActions/approvedleads'
import {
    getLoanApprovalLead, BulkPaymentICICI, verifyIciciPayments, bulfRFDbyNbfc, getUserDetailByLoanId
} from '../AdminActionCreator.component';
import { STRING, ROLE } from '../../../../utils/Constant'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../../presentationals/Loader/Loader.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import UserDetail from '../CustomerPages/UserDetailMain.container';
import { APIS } from '../../../../utils/api-factory';

let ids = { "1auHwvWSfNzNETYDEbOGv8g0Vjs8NyhQ": "PENDING", "Nrjuo9ixzrclGEIbMNHPxVGIeXYosXuJ": 'rtyui' }

let dataTosend = []
let ApplicationId = []
let newdataToCheck = []
class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            followUpStatus: STRING.APPROVED_STATUS,
            forwordPageState: 1,
            searchByDate: '',
            tabType: 'Advance',
            searchByEndDate: '',
            allUserDetail: '',
            loaderState: '',
            popupState: false,
            popupStatus: '',
            paymentData: '',
            loanIdData: '',
            userState:true,
            viewState:false,
            userDetail:'',
            actionButtonState: true,
            markCompleteBtn: true,
            dataChange:''


        }
    }
    selectAll(e) {
        if (this.state.allUserDetail && e.target.checked) {
            this.state.allUserDetail.map((dataNew, i) => {
                dataTosend.push(dataNew.loanId)
                newdataToCheck.push(i)
                document.getElementById('disburseIcici' + i).checked = true
            })
        } else {
            dataTosend = []
            newdataToCheck.map((newl, i) =>
                document.getElementById('disburseIcici' + newl).checked = false
            )


        }

    }

    rfdByNbfc() {
        if (dataTosend.length !== 0) {
            this.setState({ loaderState: true })
            bulfRFDbyNbfc(dataTosend, callBack => {
                if (callBack === 'success') {
                    console.log(callBack)
                    // let data = JSON.parse(callBack)
                    // this.setState({ paymentData: JSON.parse(callBack) })
                    // this.setState({ loanIdData: Object.keys(data) + ',' + Object.values(data) })
                    this.setState({ popupState: true, popupStatus: 'Done Successfully !', loaderState: false }, () => this.removePopup())
                    // const { admin } = this.props
                    // this.getloanLeads(admin.emailId)
                } else {
                    this.setState({ popupState: true, popupStatus: 'Please try again later', loaderState: false }, () => this.removePopup())
                }
            })
        } else {
            this.setState({ popupState: true, popupStatus: 'Please Select Appliaction ', loaderState: false }, () => this.removePopup())

        }
    }
    closePopup() {
        this.setState({ popupState: false })
    }
    getloanLeads(adminEmail) {
        this.setState({ loaderState: true })
        this.props.getLoanApprovalLead(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, '', this.state.searchByEndDate, this.state.tabType, '', '202', '', '', '',
            (callBack) => {
                this.setState({ loaderState: false, allUserDetail: callBack })
            })
    }
    refresh() {
        const { admin } = this.props
        this.getloanLeads(admin.emailId)
    }
    componentDidMount() {
        const { admin } = this.props
        this.getloanLeads(admin.emailId)
    }
    changehandler(e, data) {
        if (e.target.checked) {
            dataTosend.push(data.loanId)

        } else {
            let check = dataTosend.includes(data.loanId)
            if (check) {
                const index = dataTosend.indexOf(data.loanId);
                if (index > -1) {
                    dataTosend.splice(index, 1);

                }
            }
        }
    }
    paginationHandler(type) {
        if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,

                }, () => this.getloanLeads())
            }
        } if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,

            }, () => this.getloanLeads())
        }
    }
    paymentAndVerificationHandler(type) {
        if (type === 'verify') {
            this.setState({ loaderState: true })
            if (dataTosend.length === 0) {
                this.setState({ popupState: true, popupStatus: 'Please Select Appliaction To verify', loaderState: false }, () => this.removePopup())
            } else {
                verifyIciciPayments(dataTosend, callBack => {
                    this.setState({ paymentData: JSON.parse(callBack) })

                    if (callBack) {
                        this.setState({ popupState: true, popupStatus: 'Check Payment Status Column', loaderState: false }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please try again later', loaderState: false }, () => this.removePopup())
                    }


                })

            }
        }
        if (type === 'payment') {
            if (dataTosend.length !== 0) {
                this.setState({ loaderState: true })
                BulkPaymentICICI(dataTosend, callBack => {
                    if (callBack) {
                        let data = JSON.parse(callBack)

                        this.setState({ paymentData: JSON.parse(callBack) })
                        this.setState({ loanIdData: Object.keys(data) + ',' + Object.values(data) })
                        this.setState({ popupState: true, popupStatus: 'Check Payment Status Column', loaderState: false }, () => this.removePopup())

                    } else {
                        this.setState({ popupState: true, popupStatus: 'Please try again later', loaderState: false }, () => this.removePopup())
                    }
                })
            } else {
                this.setState({ popupState: true, popupStatus: 'Please Select Appliaction for payment', loaderState: false }, () => this.removePopup())

            }
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }
    getData(val) {
        this.setState({ dataChange: val })
    }
    backHandler(data) {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false
        })
        if (this.state.dataChange == 'yes') {
            this.getloanLeads(admin.emailId)
            this.setState({ dataChange: '' })
        }
        // this.getLoanLead()
    }
    userDetailHandler(data) {
        this.setState({ loaderState: true })
        if (data.loanId != '' && data.loanId != null) {
            getUserDetailByLoanId(data.loanId,
                (callBack) => {

                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: callBack,
                        userDetail: data,
                        loaderState: false
                    })
                })
        } else {
            //    console.log(APIS.USER_DETAIL_BY_ID + data.userId)
            fetch(APIS.USER_DETAIL_BY_ID + data.userId)
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: res,
                        userDetail: data,
                        loaderState: false
                    })
                })


        }
    }
    render() {
        const { admin} = this.props;

        return (
            <div>
                {this.state.userState == true ?

                    <Ui
                    userDetailHandler={this.userDetailHandler.bind(this)}
                        rfdByNbfc={this.rfdByNbfc.bind(this)}
                        refresh={this.refresh.bind(this)}
                        selectAll={this.selectAll.bind(this)}
                        pageNumber={this.state.forwordPageState}
                        paginationHandler={this.paginationHandler.bind(this)}
                        loanIdData={this.state.loanIdData}
                        ApplicationId={ApplicationId}
                        paymentData={this.state.paymentData}
                        allUserDetail={this.state.allUserDetail}
                        changehandler={this.changehandler.bind(this)}
                        paymentAndVerificationHandler={this.paymentAndVerificationHandler.bind(this)}
                    />
                    : ""}
                       {this.state.viewState == true ?
                    <UserDetail
                        dataChange={this.state.dataChange}
                        allDetail={this.state.allDetail}
                        userDetails={this.state.userDetail}
                        admin={admin}
                        getData={this.getData.bind(this)}
                        backHandler={this.backHandler.bind(this)}
                        actionButtonState={this.state.actionButtonState}
                        markCompleteBtn={this.state.markCompleteBtn}
                    />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupState == true ? (
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }
}
const mapStateToProps = ({ allUserDetail, loanApprovalLead }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLead: loanApprovalLead,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLead
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Bank);