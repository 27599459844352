import React from "react";
import { APIS } from "../../../../../utils/api-factory";
import { ROLE, STRING, TYPE } from "../../../../../utils/Constant";
import { approveLoanApi, checkUserDoc, saveActionLogs } from "../../AdminActionCreator.component";
import ConfirmationPopup from '../../../../presentationals/Popup/ConfirmationPopup.component';
import Popup from '../../../../presentationals/Popup/Popup.component';
import ReactDatePicker from "react-datepicker";
import { _formatDateInDash } from "../../../../../utils/validation";
import moment from "moment";
import PaidDataPopup from "../../../Admin/CustomerPages/Paid.popup";
import ApprovalTab from "./approval";


class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // allDetail: this.props.allDetail,
            actionPopup: true,
            viewTab: '',
            allDetail: this.props.userDetail,
            popupState: false,
            popupStatus: '',
            reason: '',
            confirmationPopupState: false,
            confirmationText: '',
            loanStatusState: '',
            mailState: '',
            approveDataState: false,
            softApproveDataState: false,
            loanApproveDate: '',
            loanApproveAmount: '',
            loanApproveTenure: '',
            loanPaybackDate: '',
            paybackDateToSave: '',
            score: '',
            actionChangeValue: '',
            dateLoanApprove: '',
            dateLoanPaid: '',
            varificationPopupViewState: false,
            actionReason: '',
            OtherInput: false,
            bankDetailPopup: false,
            actionLogs: {
                userIdState: '',
                actionState: '',
                loanIdState: '',
                userEmailState: '',
                empIdState: ''
            },

            disburseDataState: false,
            paidDataState: false,
            SelectNbfcPopupState: false,
            nbfcNameForApprove: 'nbfc',
            searchByEndDate: '',
            tabType: '',
            sendAttribute: '',
            isStaff: '',
            verficationMailType: '',
            esignDisable: false,

            ///disbursestate
            actionLogsDisburse: {
                userIdState: '',
                actionState: '',
                loanIdState: '',
                userEmailState: '',
                empIdState: ''
            },
            actionChangeValuedisburse: '',
            disburseData: {
                disburseDate: _formatDateInDash(new Date()),
                disburseDateToShow: '',
                code: '',
                pin: '',
                insuranceAmount: ''
                // expiryDate: '',
                // expiryDateShow: ''
            },
            errorMsg: '',
            disabled: false,
            expiryDate: '',
            confirmationPopupDisburse: false,
            mailStateDisburse: true,
            showButton: false,
            errorMessage:''
        }
    }
    buttonHandle(type) {
        this.setState({ viewTab: type })
        if (type === 'reject') {
            fetch(APIS.APPROVE_REJECT_REASON + 'reject')
                .then(res => res.json())
                .then(res => {
                    this.setState({ actionReason: res })
                })
        }
        if (type === 'disburse') {
            var oneYearFromNow = new Date()
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
            let datetes = moment(oneYearFromNow).format('DD-MM-YYYY')

            this.setState({ expiryDate: datetes })

        }

    }
    cancelLoanConfirm(buttonType) {
        const { admin, userDetail, allDetail } = this.props
        if (buttonType == 'yes') {
            this.setState({
                confirmationPopupState: false
            })
            if (this.state.loanStatusState == STRING.APPROVED_STATUS) {
                this.setState({
                    approveDataState: true
                })
            } else if (this.state.loanStatusState == STRING.DISBURSED) {
                this.setState({
                    disburseDataState: true,
                })
            } else if (this.state.loanStatusState == STRING.REJECT_STATUS || this.state.loanStatusState == STRING.PERMANENT_REJECT) {
                if (userDetail.typeOfProduct == 'Loan') {
                    approveLoanApi(
                        userDetail
                        , this.state.loanStatusState
                        , this.state.reason
                        , ''
                        , this.state.mailState
                        , this.state.score
                        , admin.emailId
                        , []
                        , admin.nbfcName
                        , '',
                        (callBack) => {
                            if (callBack == 'success') {
                                // this.props.getLoanApprovalLead(STRING.SELECT_ALL, 1, admin.emailId, '', admin.nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '','','',
                                //     (callBack) => {

                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Loan has been Rejected successfully',
                                })
                                this.removePopup();
                                // });
                            }
                        })
                } else if (userDetail.typeOfProduct == 'Gift Card') {
                    giftCardSanction(userDetail, this.state.loanStatusState, this.state.reason, '', this.state.mailState, this.state.score, admin.emailId, [], admin.nbfcName,
                        callBack => {
                            if (callBack == 'success') {
                                // this.props.getLoanApprovalLead(STRING.SELECT_ALL, 1, admin.emailId, '', admin.nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '','','',
                                //     (callBack) => {

                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Loan has been Rejected successfully',
                                }, () => this.props.getData('yes'))
                                this.removePopup();
                                // });
                            }
                        })
                }
            } else if (this.state.loanStatusState == STRING.PAID) {
                this.setState({
                    paidDataState: true,
                })
            } else if (this.state.loanStatusState == "softApproval") {
                this.setState({
                    SelectNbfcPopupState: true,
                })
            } else if (this.state.loanStatusState == "removeNBFC") {
                fetch(APIS.REMOVE_APPLICATION_FROM_NBFC + this.state.allDetail.loanId + '&nbfc=')
                    .then(res => res.text())
                    .then(text => {
                        this.setState({
                            popupState: true,
                            popupStatus: text,
                        })
                        this.removePopup();
                    })

            }
        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }
    reason(e) {
        let reasonType = e.target.value
        if (reasonType == 'Other') {
            this.setState({ OtherInput: true, reason: '', })
        } else {
            this.setState({ reason: reasonType, OtherInput: false })
        }
    }
    otherReason(e) {
        this.setState({ reason: e.target.value })
    }
    actionChangeHandler(e) {
        let actionLogs = Object.assign({}, this.state.actionLogs);
        actionLogs.actionState = e.target.value
        this.setState({ actionChangeValue: e.target.value, actionLogs, reason: '' })
        // if (e.target.value == 'reject' || e.target.value == 'Reject Without Mail' || e.target.value == STRING.PERMANENT_REJECT) {
        //     fetch(APIS.APPROVE_REJECT_REASON + 'reject')
        //         .then(res => res.json())
        //         .then(res => {
        //             this.setState({ actionReason: res })
        //         })
        // }

    }
    actionChangeDisburse(e) {
        let chn = Object.assign({}, this.state.actionLogsDisburse);
        chn.actionState = e.target.value
        this.setState({ actionChangeValuedisburse: e.target.value, actionLogsDisburse: chn })
    }
    actionSubmitHandler() {
        const { userDetails, userDetail } = this.props;
        if (userDetail.typeOfProduct == 'Loan') {

            saveActionLogs(this.state.actionLogs, '',
                (callBack) => {


                    if (callBack == 'success') {
                        if (this.state.actionChangeValue == "approve") {
                            this.setState({
                                loanStatusState: STRING.APPROVED_STATUS,
                                mailState: true,
                                loanApproveAmount: userDetail.loanAmount,
                                confirmationText: 'Are you sure, you want to APPROVE this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "reject") {
                            if (this.state.reason !== '' && this.state.reason !== 'Other') {
                                this.setState({
                                    loanStatusState: STRING.REJECT_STATUS,
                                    mailState: true,
                                    confirmationText: 'Are you sure, you want to REJECT this LOAN',
                                    confirmationPopupState: true
                                })
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Enter Reject Reason',
                                })
                                this.removePopup();
                            }
                        } else if (this.state.actionChangeValue == "Approve Without Mail") {
                            this.setState({
                                loanStatusState: STRING.APPROVED_STATUS,
                                loanApproveAmount: userDetail.loanAmount,
                                mailState: false,
                                confirmationText: 'Are you sure, You want to APPROVE this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "Reject Without Mail") {
                            if (this.state.reason !== '' && this.state.reason !== 'Other') {

                                this.setState({
                                    loanStatusState: STRING.REJECT_STATUS,
                                    mailState: false,
                                    confirmationText: 'Are you sure, you want to REJECT this LOAN',
                                    confirmationPopupState: true
                                })
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Enter Reject Reason',
                                })
                                this.removePopup();
                            }
                        } else if (this.state.actionChangeValue == "Disbursed") {
                            if (userDetails.loanFrom === 'bazarBussiness') {
                                this.setState({
                                    loanStatusState: STRING.DISBURSED,
                                    mailStateDisburse: true,
                                    confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                    confirmationPopupDisburse: true,
                                    popupState: false
                                })
                            }
                            if (userDetails.loanFrom !== 'bazarBussiness') {

                                if (userDetail.statusId == STRING.APPROVED_STATUS) {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailStateDisburse: true,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                    })
                                } else {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Kindly APPROVE the loan first!',
                                    })
                                    this.removePopup();
                                }
                            }
                        } else if (this.state.actionChangeValue == "Disbursed Without Mail") {
                            if (userDetails.loanFrom === 'bazarBussiness') {
                                this.setState({
                                    loanStatusState: STRING.DISBURSED,
                                    mailStateDisburse: false,
                                    confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                    confirmationPopupDisburse: true,
                                    popupState: false
                                })
                            }
                            if (userDetails.loanFrom !== 'bazarBussiness') {
                                if ((userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED)) {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailStateDisburse: false,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                    })
                                } else {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Kindly APPROVE the loan first!',
                                    })
                                    this.removePopup();
                                }
                            }
                        } else if (this.state.actionChangeValue == "Paid") {
                            this.setState({
                                loanStatusState: STRING.PAID,
                                loanApproveAmount: this.state.allDetail.totalPayBackAmount,
                                confirmationText: 'Are you sure, this LOAN has been PAID',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == STRING.PERMANENT_REJECT) {
                            if (this.state.reason !== '' && this.state.reason !== 'Other') {

                                this.setState({
                                    loanStatusState: STRING.PERMANENT_REJECT,
                                    mailState: true,
                                    confirmationText: 'Are you sure, you want to Permanent REJECT this LOAN',
                                    confirmationPopupState: true
                                })
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Enter Reject Reason',
                                })
                                this.removePopup();
                            }
                        } else if (this.state.actionChangeValue == "softApproval") {
                            this.setState({
                                loanStatusState: "softApproval",
                                mailState: true,
                                confirmationText: 'Are you sure, you want to Soft Approve this LOAN',
                                confirmationPopupState: true
                            })
                        }
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: callBack,
                        })
                        this.removePopup();
                    }
                })
        } else if (userDetail.typeOfProduct == 'Gift Card') {
            saveActionLogs(this.state.actionLogs, userDetail.giftCardId,
                (callBack) => {
                    if (callBack == 'success') {
                        if (this.state.actionChangeValue == "approve") {
                            this.setState({
                                loanStatusState: STRING.APPROVED_STATUS,
                                mailState: true,
                                loanApproveAmount: userDetail.loanAmount,
                                confirmationText: 'Are you sure, you want to APPROVE this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "reject") {
                            this.setState({
                                loanStatusState: STRING.REJECT_STATUS,
                                mailState: true,
                                confirmationText: 'Are you sure, you want to REJECT this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "Approve Without Mail") {
                            this.setState({
                                loanStatusState: STRING.APPROVED_STATUS,
                                loanApproveAmount: userDetail.loanAmount,
                                mailState: false,
                                confirmationText: 'Are you sure, You want to APPROVE this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "Reject Without Mail") {
                            this.setState({
                                loanStatusState: STRING.REJECT_STATUS,
                                mailState: false,
                                confirmationText: 'Are you sure, you want to REJECT this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "Disbursed") {
                            if (userDetail.statusId == STRING.APPROVED_STATUS) {
                                this.setState({
                                    loanStatusState: STRING.DISBURSED,
                                    mailState: true,
                                    confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                    confirmationPopupState: true,
                                })
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Kindly APPROVE the loan first!',
                                })
                                this.removePopup();
                            }
                        } else if (this.state.actionChangeValue == "Disbursed Without Mail") {
                            if (userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED) {
                                this.setState({
                                    loanStatusState: STRING.DISBURSED,
                                    mailState: false,
                                    confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                    confirmationPopupState: true,
                                })
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Kindly APPROVE the loan first!',
                                })
                                this.removePopup();
                            }
                        } else if (this.state.actionChangeValue == "Paid") {
                            this.setState({
                                loanStatusState: STRING.PAID,
                                loanApproveAmount: this.state.allDetail.totalPayBackAmount,
                                confirmationText: 'Are you sure, this LOAN has been PAID',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == STRING.PERMANENT_REJECT) {
                            this.setState({
                                loanStatusState: STRING.PERMANENT_REJECT,
                                mailState: true,
                                confirmationText: 'Are you sure, you want to Permanent REJECT this LOAN',
                                confirmationPopupState: true
                            })
                        } else if (this.state.actionChangeValue == "softApproval") {
                            this.setState({
                                loanStatusState: "softApproval",
                                mailState: true,
                                confirmationText: 'Are you sure, you want to Soft Approve this LOAN',
                                confirmationPopupState: true
                            })
                        }
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: callBack,
                        })
                        this.removePopup();
                    }
                })
        }
    }
    componentDidMount() {
        const { admin } = this.props
        let actionLogs = Object.assign({}, this.state.actionLogs);
        actionLogs.userIdState = this.state.allDetail.userId
        actionLogs.loanIdState = this.state.allDetail.loanId
        actionLogs.userEmailState = this.state.allDetail.emailId
        actionLogs.empIdState = admin.emailId
        this.setState({
            actionLogs,
        })
        let chn = Object.assign({}, this.state.actionLogsDisburse);
        chn.userIdState = this.state.allDetail.userId
        chn.loanIdState = this.state.allDetail.loanId
        chn.userEmailState = this.state.allDetail.emailId
        chn.empIdState = admin.emailId
        this.setState({
            actionLogsDisburse: chn
        })
    }
   
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }
    getNextYearDate(e) {
        var oneYearFromNow = new Date(e)
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

        var datetest = moment(oneYearFromNow).format('DD-MM-YYYY')

        this.setState({ expiryDate: datetest })
    }
    disburseDataChange(e, dataType) {
        const { allDetail } = this.props

        let disburseData = Object.assign({}, this.state.disburseData)
        if (dataType == TYPE.LOAN_APPROVE_DATE) {
            this.getNextYearDate(e)
            let dateDiff = moment(allDetail.installmentDate, 'DD-MM-YYYY').diff(
                e,
                'days'
            )
            disburseData.disburseDate = _formatDateInDash(e)
            disburseData.disburseDateToShow = e
            if (dateDiff < 7) {
                this.setState({
                    errorMsg: 'Days Validation Error!'
                })
            } else {
                this.setState({
                    errorMsg: ''
                })
            }
        } else if (dataType == 'code') {
            disburseData.code = e.target.value
        } else if (dataType == 'pin') {
            disburseData.pin = e.target.value
        } else if (dataType == 'expiryDate') {
            //   disburseData.expiryDate = _formatDateInDash(e)
            //     disburseData.expiryDateShow = e
            //   this.setState({expiryDate:_formatDateInDash(e)})
        } else if (dataType == 'sku') {
            disburseData.sku = e.target.value
        } else if (dataType == 'insuranceAmount') {
            disburseData.insuranceAmount = e.target.value
        }
        this.setState({
            disburseData
        })
    }
    disburseDataHandler() {
        const { allDetail, admin, mailState, userDetails } = this.props
        this.setState({ disabled: true })
        if (userDetails.loanFrom === 'vreedhi') {
            if (this.state.disburseData.insuranceAmount !== '') {
                fetch(APIS.DISBERSE_API + userDetails.loanId + '&date=' + this.state.disburseData.disburseDate + '&empId=' + admin.emailId + '&mail=' + this.state.mailStateDisburse + '&nbfcName=' + (userDetails.loanFrom !== "vreedhi" ? admin.nbfcName : 'vreedhi') + '&insuranceAmount=' + this.state.disburseData.insuranceAmount)
                    .then(json => json.text())
                    .then(res => {
                        if (res == 'success') {
                            this.setState({ disabled: false, popupState: true, popupStatus: 'Loan Disbursed Successfully!' }, () => this.removePopup())
                        } else {
                            this.setState({ popupState: true, popupStatus: 'Plaese try again later!' }, () => this.removePopup())
                        }
                    })
            } else {
                this.setState({ popupStatus: 'Please enter Insurance Amount First!', popupState: true, disabled: false }, () => this.removePopup())

            }
        }
        if (userDetails.typeOfProduct == 'Loan' && userDetails.loanFrom !== 'vreedhi') {
            fetch(APIS.DISBERSE_API + userDetails.loanId + '&date=' + this.state.disburseData.disburseDate + '&empId=' + admin.emailId + '&mail=' + this.state.mailStateDisburse + '&nbfcName=' + (userDetails.loanFrom === "vreedhi" ? 'vreedhi' : userDetails.loanFrom === 'bazarBussiness' ? 'bazarBussiness' : admin.nbfcName) + '&insuranceAmount=' + this.state.disburseData.insuranceAmount)
                .then(json => json.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({ disabled: false, popupState: true, popupStatus: 'Loan Disbursed Successfully!' }, () => this.removePopup())
                    } else {
                        this.setState({ popupState: true, popupStatus: 'Plaese try again later!' }, () => this.removePopup())
                    }
                })
        }

        else if (
            userDetails.typeOfProduct == 'Gift Card'
        ) {

            fetch(
                APIS.DISBURSE_GIFT_CARD_API +
                userDetails.giftCardId +
                '&date=' +
                this.state.disburseData.disburseDate +
                '&empId=' +
                admin.emailId +
                '&mail=' +
                mailState +
                '&nbfcName=' +
                admin.nbfcName +
                '&voucherCode=' +
                '' +
                '&voucherPin=' +
                '' +
                '&expiryDate=' +
                ''
            )
                .then(json => json.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({ disabled: false })
                    }
                })
        }
    }
    disburseSubmitNew() {
        // this.setState({ actionChangeValue: 'Disbursed' })
        const { userDetails, userDetail } = this.props;
        if (this.state.actionChangeValuedisburse !== '') {
            if (userDetail.typeOfProduct == 'Loan') {
                saveActionLogs(this.state.actionLogsDisburse, '',
                    (callBack) => {
                        if (callBack == 'success') {
                            if (this.state.actionChangeValuedisburse == "Disbursed") {
                                if (userDetails.loanFrom === 'bazarBussiness') {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailStateDisburse: true,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                        popupState: false
                                    })
                                }
                                if (userDetails.loanFrom !== 'bazarBussiness') {

                                    if (userDetail.statusId == STRING.APPROVED_STATUS) {
                                        this.setState({
                                            loanStatusState: STRING.DISBURSED,
                                            mailStateDisburse: true,
                                            confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                            confirmationPopupDisburse: true,
                                        })
                                    } else {
                                        this.setState({
                                            popupState: true,
                                            popupStatus: 'Kindly APPROVE the loan first!',
                                        })
                                        this.removePopup();
                                    }
                                }
                            } else if (this.state.actionChangeValuedisburse == "Disbursed Without Mail") {
                                if (userDetails.loanFrom === 'bazarBussiness') {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailStateDisburse: false,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                        popupState: false
                                    })
                                }
                                if (userDetails.loanFrom !== 'bazarBussiness') {
                                    if ((userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED)) {
                                        this.setState({
                                            loanStatusState: STRING.DISBURSED,
                                            mailStateDisburse: false,
                                            confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                            confirmationPopupDisburse: true,
                                        })
                                    } else {
                                        this.setState({
                                            popupState: true,
                                            popupStatus: 'Kindly APPROVE the loan first!',
                                        })
                                        this.removePopup();
                                    }
                                }
                            }
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: callBack,
                            })
                            this.removePopup();
                        }
                    })
            } else if (userDetail.typeOfProduct == 'Gift Card') {
                saveActionLogs(this.state.actionLogsDisburse, userDetail.giftCardId,
                    (callBack) => {
                        if (callBack == 'success') {
                            if (this.state.actionChangeValuedisburse == "Disbursed") {
                                if (userDetail.statusId == STRING.APPROVED_STATUS) {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailState: true,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                    })
                                } else {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Kindly APPROVE the loan first!',
                                    })
                                    this.removePopup();
                                }
                            } else if (this.state.actionChangeValuedisburse == "Disbursed Without Mail") {
                                if (userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED) {
                                    this.setState({
                                        loanStatusState: STRING.DISBURSED,
                                        mailState: false,
                                        confirmationText: 'Are you sure, you want to DISBURSE this LOAN',
                                        confirmationPopupDisburse: true,
                                    })
                                } else {
                                    this.setState({
                                        popupState: true,
                                        popupStatus: 'Kindly APPROVE the loan first!',
                                    })
                                    this.removePopup();
                                }
                            }
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: callBack,
                            })
                            this.removePopup();
                        }
                    })
            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please Select Status!',
            })
            this.removePopup();
        }
    }
    disburseConfirm(buttonType) {
        if (buttonType == 'yes') {
            this.disburseDataHandler()
            this.setState({ confirmationPopupDisburse: false })
        } else {
            this.setState({ confirmationPopupDisburse: false })

        }
    }
    closePaidPopup() {

    }
    isValidReason() {

        return this.state.reason.trim() !== '' && this.state.reason !== 'Other' && this.state.reason !== 'other' && this.state.reason !== 'OTHER' && this.state.reason !== ''
    }

    render() {
        const { admin, userDetails, allDetail } = this.props
        return (
            <>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                {this.state.confirmationPopupDisburse == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.disburseConfirm.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                <div className="container-fluid details-new" >
                    <div className="row" style={{ background: '#1D3557', cursor: 'pointer', color: '#fff', borderRadius: '6px', padding: '10px', marginTop: '20px', borderBottomLeftRadius: this.state.actionPopup ? '0px' : '6px', borderBottomRightRadius: this.state.actionPopup ? '0px' : '6px' }} onClick={() => this.setState({ actionPopup: !this.state.actionPopup })} >
                        <div className="" >
                            Action For Loan
                            {!this.state.actionPopup ? <i className="fa fa-chevron-down text-right" style={{ float: 'inline-end' }}></i>
                                : <i className="fa fa-chevron-up text-right" style={{ float: 'inline-end' }}></i>}


                        </div>

                    </div>
                    {this.state.actionPopup ?
                        <>
                            <div className="row " style={{ borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', minHeight: '300px', border: '1px solid rgba(0, 0, 0, 0.15)' }}>

                                <div className="btn-group btn-grp-own-action" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px' }}>
                                    {/* {admin != null && admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                     <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'reject' ? '#267DFF' : '#F1F7FF', color: this.state.viewTab === 'reject' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('reject')}> Reject/Permanent Reject </button> 
                                     : ""} */}
                                    {admin != null && admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                     <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'reject' ? '#0ea29b' : '#F1F7FF', color: this.state.viewTab === 'reject' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('reject')}> Reject/Permanent Reject </button> 
                                     : ""}
                                    {admin != null && admin.rolelist.indexOf(ROLE.ACTION) >= 0 ? <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'approve' ? '#0ea29b' : '#F1F7FF', color: this.state.viewTab === 'approve' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('approve')} > Approve/Tentative Approval </button> : ""}
                                    {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ? <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'disburse' ? '#0ea29b' : '#F1F7FF', color: this.state.viewTab === 'disburse' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('disburse')} > Disbursed </button> : ""}
                                    {admin != null && admin.rolelist.indexOf(ROLE.ACTION_PAID) >= 0 ?
                                        <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'paid' ? '#0ea29b' : '#F1F7FF', color: this.state.viewTab === 'paid' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('paid')} > Paid </button>
                                        : ""}
                                </div>

                                {this.state.viewTab === 'reject' ?
                                    <div className="action-small-box" style={{ marginBottom: '20px', paddingBottom: window.innerHeight < 650 ? '200px' : '20px', overflow: 'auto', maxHeight: '400px' }} >
                                        <label>Status</label>
                                        <select className="form-control" onChange={(e) => this.actionChangeHandler(e)}>
                                            <option value="">Select Action</option>
                                            {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                                <option value="reject">Reject</option>
                                                : ""}
                                            {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                                <option value="Reject Without Mail">Reject Without Mail</option>
                                                : ""}
                                            {admin.rolelist.indexOf(ROLE.ACTION) >= 0 ?
                                                <option value={STRING.PERMANENT_REJECT}>Permanent Reject</option>
                                                : ""}
                                        </select>
                                        {this.state.actionChangeValue !== '' ?

                                            <>
                                                <label style={{ marginTop: '10px' }}>Reject Reason</label>
                                                <select onChange={e => this.reason(e)} className="form-control"  >
                                                    <option value={''}>Select Reason</option>
                                                    {this.state.actionReason !== '' ?
                                                        this.state.actionReason.map((reason, i) => {
                                                            return (
                                                                <option key={i} value={reason.message}>{reason.message}</option>
                                                            )
                                                        })
                                                        : ""}
                                                    <option value={'Other'}>Similar Profile</option>
                                                </select>
                                            </>
                                            : ""}
                                        {this.state.OtherInput == true ?
                                            <div className=" " >
                                                <label style={{ marginTop: '10px' }}>Custom Reject Reason</label>
                                                <input type="text" className="form-control" onChange={e => this.otherReason(e)} placeholder="Enter Other Reason" />

                                            </div>
                                            : ""}
                                        {this.state.actionChangeValue !== '' ?
                                            <div className="">
                                                <button className="btn btn-primary" disabled={!this.isValidReason()} onClick={() => this.actionSubmitHandler()} style={{ marginTop: '20px' }}>Save</button>
                                            </div>
                                            : ''}
                                    </div>
                                    : ""}
                                {this.state.viewTab === 'disburse' ?

                                    <div className="action-small-box" style={{ marginBottom: '20px', paddingBottom: window.innerHeight < 650 ? '200px' : '20px', overflow: 'auto', maxHeight: '400px' }} >
                                        <label>Status</label>
                                        <select className="form-control" onChange={(e) => this.actionChangeDisburse(e)}>
                                            <option value="">Select Action</option>
                                            {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                <option value="Disbursed Without Mail">Disbursed Without Mail</option>
                                                : ""}
                                            {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                <option value="Disbursed">Disbursed</option>
                                                : ""}

                                        </select>
                                        {this.state.actionChangeValuedisburse !== '' ?
                                            <>
                                                <label className='font-12px'>Select Disburse Date</label>
                                                <ReactDatePicker
                                                    value={this.state.disburseData.disburseDate}
                                                    selected={this.state.disburseData.disburseDateToShow}
                                                    onChange={e => this.disburseDataChange(e, TYPE.LOAN_APPROVE_DATE)}
                                                    className='form-control'
                                                    placeholderText='select Date'
                                                    dateFormat='DD/MM/YYYY'
                                                />
                                            </>
                                            : ""}

                                        {userDetails.loanFrom === "vreedhi" ?
                                            <>
                                                <label className='font-12px'>Insurance Amount</label>
                                                <input
                                                    className='form-control'
                                                    placeholder='Insurance Amount'
                                                    onChange={e => this.disburseDataChange(e, 'insuranceAmount')}
                                                />
                                            </>
                                            : ""}
                                        {this.state.actionChangeValuedisburse !== '' ?
                                            <button
                                                style={{ marginTop: '20px' }}
                                                className='btn btn-primary'
                                                disabled={this.state.disabled}
                                                onClick={e => this.disburseSubmitNew()}
                                            >
                                                SAVE
                                            </button>
                                            : ''}
                                    </div>
                                    : ""}

                                {this.state.viewTab === 'paid' ?
                                    <div className="action-small-box" style={{ marginBottom: '20px', paddingBottom: window.innerHeight < 650 ? '200px' : '20px', overflow: 'auto', maxHeight: '400px' }} >
                                        <PaidDataPopup
                                            userDetail={userDetails}
                                            closePaidPopup={this.closePaidPopup.bind(this)}
                                            allDetail={this.state.allDetail}
                                            admin={admin}
                                        />
                                    </div>
                                    : ""}
                                {this.state.viewTab === 'approve' ?
                                    <div className="action-small-box" style={{ marginBottom: '20px', paddingBottom: window.innerHeight < 650 ? '200px' : '20px', overflow: 'auto', maxHeight: '400px' }} >

                                        <ApprovalTab userDetails={userDetails} admin={admin} allDetail={allDetail} />
                                    </div>
                                    : ""}

                            </div>
                        </>
                        : ""}
                </div>
            </>
        )
    }
}
export default Action