import React, { useEffect, useState } from "react";
import { aadharVerificationDetails, aadharXmlDetails, getFraudDetailApiNew, panProfileDetails } from "../AdminActionCreator.component";
import {  getCrifAnalysisStatus, getFinbitAnalysis, getNotes, getPersonalAnalysisReport, getSmsAnalysisStatus, saveCommentForCreditAssesment, similarProfileData } from "./analysisactionCreator";
import { PieChart, Pie, Cell } from 'recharts';
import ProfileLogo from '../../../../images/profile.png'
import Loader from '../../../presentationals/Loader/Loader.component'
import Credit from "./creditanalysis";
import DashboardUi from './DashboardWithUpdatedChanges'
import FraudTable from './FraudTable'
import FinBit from "./FinBitAnalysis.container";
import { APIS } from "../../../../utils/api-factory";
import CrifNew from "./crifNewPage";
import Kyc from "./analysissubComponent/kyc.component";
import Internal from "./analysissubComponent/internal.component";
import FinanceAnalysis from "./analysissubComponent/financial.component";
import CrifAnalysis from "./analysissubComponent/crif.component";
import SmsTable from './TableSms'
import Legalsms from './legalSms'
import EmismsTable from './emisms'
import OverdueSms from './overduesms'
import RawMsg from '../CustomerPages/RawMsg.container'
import Messages from '../../../containers/Admin/CustomerPages/MessageAnalysis.container'
import BankObligationTable from '../../../containers/Admin/Analysis/bankObligationTable'
import PolicyDetails from "./policyDetailsNew";
import ScoreNew from "../CustomerPages/scoringNewMain";
import OverDueException from "./overDueExceptionMsg";
import ApplicantLocation from "../CustomerPages/UserLocation.container";
import Repeat from "../CustomerPages/RepeatDecision.container";
import { ROLE } from "../../../../utils/Constant";


const COLORSPINK = ['#514C9F', '#D8D4FE'];
const ColorGreen = ['#95C90A', '#DFEFB6']
let paginationNumber = 10

const Analysis = ({ allDetail, admin, userDetails }) => {
    const [crifData, setCrifAnalysisData] = useState('')
    const [menuSize, setMenusize] = useState('big')
    const [viewPage, setViewPage] = useState('')
    const [subTabPersonal, setsubTabPersonal] = useState('pan')
    const [personalData, setpersonalData] = useState('')
    const [karzaData, setKarzadata] = useState({})
    const [aadhaarData, setAadhaarData] = useState({})
    const [aadhaarXmlData, setAadharXmlData] = useState({})
    const [personal, setPersonal] = useState(false)
    const [pan, setpan] = useState(false)
    const [aadhar, setaadhar] = useState(false)
    const [chartDataState, setchartDataState] = useState([])
    const [location, setlocation] = useState(false)
    const [chartDataStateLocation, setchartDataStateLocation] = useState([])
    const [kycState, setkycState] = useState(false)
    const [kycSubStatePan, setkycSubStatePan] = useState(false)
    const [kycSubStateAAdhaar, setkycSubStateAAdhaar] = useState(false)
    const [kycSubStateLocation, setkycSubStateLocation] = useState(false)
    const [internalState, setinternalState] = useState(false)
    const [matchedSimilarData, setmatchedSimilarData] = useState('')
    const [loaderState, setLoader] = useState(false)
    const [tableMinPagination, settableMinPagination] = useState(0)
    const [tableMaxPagination, settableMaxPagination] = useState(paginationNumber)
    const [filterData, setFilterData] = useState('')
    const [filterType, setFilterType] = useState('')
    const [fraudData, setFraudData] = useState('')
    const [allSimilarProfileData, setallSimilarProfileData] = useState('')
    const [smsAnalysis, setsmsAnalysis] = useState(false)
    const [smsData, setSmsData] = useState('')
    const [finbitData, setFinbitData] = useState('')
    const [financialTab, setfinancialTab] = useState('')
    const [finBitMainTab, setfinBitMainTab] = useState(false)
    const [crifMainTab, setcrifMainTab] = useState(false)
    const [analysisData, setanalysisData] = useState('')
    const [crifTab, setcrifTab] = useState('')
    const [userCrifData, setuserCrifData] = useState('')
    const [regenerate, setregenerate] = useState(0)
    const [smsTab, setSmsTab] = useState('')
    const [secondTab, setSecondT] = useState('')
    const [crifLoaderData, setcrifLoaderData] = useState(false)
    const [similarProfileLoader, setSimilarProfileLoader] = useState(false)
    const [, updateState] = React.useState();
    const [notesText, setNotesText] = useState('')
    const [notesType, setNotesType] = useState('')
    const [notesPopup, setNotesPopup] = useState(false)
    const [msgData, setMessageData] = useState('')
    const [loadarState, setloadarState] = useState(false)
    const [locationNotes, setLocationNotes] = useState(false)
    const [panNotes, setPanNotes] = useState(false)
    const [aadhaarNotes, setAAdharNotesNotes] = useState(false)
    const [analysisDataTab, setAnalysisTableData] = useState('')
    const [policyId, setPolicyId] = useState('')
    const [parseData, setparseData] = useState('')
    const [sortedData, setSortedData] = useState([])
    const [repeatUserData, setrepeatUserData] = useState('')
    const [htmlData, setHtmlData] = useState('')
    const [regenerateButton, setregenerateButton] = useState(true)

    const forceUpdate = React.useCallback(() => updateState({}), []);


    const sortByDisbursedDate = (data) => {
        try {
            let dataArray = Array.isArray(data) ? data : [data];

            setSortedData(dataArray.sort((a, b) => {
                const dateAValue = a["LOAN-DETAILS"]["DISBURSED-DATE"];
                const dateBValue = b["LOAN-DETAILS"]["DISBURSED-DATE"];

                // Check if the values are defined before splitting
                const dateA = dateAValue ? new Date(dateAValue.split('-').reverse().join('-')) : null;
                const dateB = dateBValue ? new Date(dateBValue.split('-').reverse().join('-')) : null;

                if (a["LOAN-DETAILS"]["ACCOUNT-STATUS"] === "Active" && b["LOAN-DETAILS"]["ACCOUNT-STATUS"] !== "Active") {
                    return -1;
                } else if (a["LOAN-DETAILS"]["ACCOUNT-STATUS"] !== "Active" && b["LOAN-DETAILS"]["ACCOUNT-STATUS"] === "Active") {
                    return 1;
                } else {
                    // Check if dateA and dateB are not null before comparison
                    return dateB && dateA ? dateB - dateA : 0;
                }
            }))

        } catch (error) {
            console.error("Error sorting data:", error);
            // Additional error handling if needed
        }
    };

    const checkRepeatUser = () => {
        fetch(APIS.REPEAT_USER_CHECK + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                setrepeatUserData(json.repeatUser)
            })
    }

    useEffect(() => {
        getEmployeesRepeat()
        if (admin != null && admin.rolelist.indexOf(ROLE.ANALYSIS_DASHBOARD) >= 0) {
            setViewPage('dashboard')
        } else {

        }
    }, [])
    const pageHandler = (type) => {
        if (type !== 'dashboard') {
            setViewPage(type)
        }
        if (type === 'dashboard' || type === 'analysisTab' || type === 'scoring') {

            setViewPage(type)
            setsmsAnalysis(false)
            setfinancialTab(false)
            setcrifMainTab(false)
            setinternalState(false)
            setkycState(false)
            setSmsTab('')


        }
    }
    const personalSubTab = (type) => {
        setsubTabPersonal(type)
        if (type === 'pan') {
            setPersonal(!personal)
            getpanProfileDetailsData()
        }
        if (type === 'aadhar') {
            setaadhar(!aadhar)
            getAadhaarData()
            aadharXmlDetailsData()
        }
        if (type === 'location') {
            setlocation(!location)
        }
    }
    const personaDataApi = () => {
        getPersonalAnalysisReport(allDetail.userId, allDetail.loanId, callback => {
            let lt = parseFloat(callback.aadharAddressMatch)
            let data = [
                { name: '', value: Math.round(lt) },
                { name: '', value: (100 - Math.round(lt)) }
            ]
            setchartDataStateLocation(data)
            setpersonalData(callback)
        })
    }
    const makegraphDataAadhar = (To) => {
        let data = [
            { name: '', value: parseFloat(To) },
            { name: '', value: (100 - parseFloat(To)) }
        ]
        setchartDataState(data)


    }
    const similarProfileDataApiData = () => {
        similarProfileData(allDetail.loanId, callback => {
            setmatchedSimilarData(callback.matchBy)
        })
    }
    useEffect(() => {
        similarProfileDataApiData()
        personaDataApi()
        getpanProfileDetailsData()
        getSmsAnalysisStatusData()
        getFinbitAnalysisData()
        getCrifAnalysisStatusdata()
        getAnalysisData()
        checkRepeatUser()


    }, [])
    const getpanProfileDetailsData = (userId) => {
        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                setKarzadata(callBack)
            }
        })
    }
    const getAadhaarData = () => {
        aadharVerificationDetails(allDetail.userId, callBack => {
            setAadhaarData(callBack)

        })
    }
    const aadharXmlDetailsData = () => {
        aadharXmlDetails(allDetail.userId, callBack => {
            setAadharXmlData(callBack)
            if (callBack && callBack.percentageOfMatch) {
                makegraphDataAadhar(callBack.percentageOfMatch)
            }
        })
    }

    const downloadFile = (filename, content) => {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + content);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }
    const menusizeFunc = () => {
        if (menuSize === 'big') {
            setMenusize('small')
        }
        if (menuSize === 'small') {
            setMenusize('big')
        }
    }
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            //   <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="">
            //     {`${(percent * 100).toFixed(0)}%`}
            //   </text>
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="12">{(chartDataState[0].value) + '%'}</tspan>
                {/* <tspan fontSize="14">{(percent * 100).toFixed(0)}</tspan> */}
            </text>
        );
    };
    const renderCustomizedLabelLocation = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            //   <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="">
            //     {`${(percent * 100).toFixed(0)}%`}
            //   </text>
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="12">{(chartDataStateLocation[0].value) + '%'}</tspan>
                {/* <tspan fontSize="14">{(percent * 100).toFixed(0)}</tspan> */}
            </text>
        );
    };
    const kycStateHandler = (mainType, subType) => {
        setSmsTab('')
        if (mainType === 'kyc') {
            pageHandler('personal')
            setkycState(!kycState)
            setkycSubStatePan(false)
            setkycSubStateAAdhaar(false)
            setkycSubStateLocation(false)
            setSecondT('')
            setSmsTab('')



        }
        if (subType === 'pan') {
            getNotes(allDetail.loanId, allDetail.userId, 'pan', callBack => {
                setPanNotes(callBack)
            })
            pageHandler('personal')
            setkycSubStatePan(true)
            setkycSubStateAAdhaar(false)
            setkycSubStateLocation(false)
            setSecondT('')
            setSmsTab('')


        }
        if (subType === 'aadhaar') {
            getNotes(allDetail.loanId, allDetail.userId, 'aadhaar', callBack => {
                setAAdharNotesNotes(callBack)
            })
            aadharXmlDetailsData()
            pageHandler('personal')
            setkycSubStateLocation(false)
            setkycSubStateAAdhaar(true)
            setkycSubStatePan(false)
            setSecondT('')
            setSmsTab('')

        }
        if (subType === 'location') {
            getNotes(allDetail.loanId, allDetail.userId, 'location', callBack => {
                setLocationNotes(callBack)
            })
            pageHandler('personal')
            setkycSubStateLocation(true)
            setkycSubStateAAdhaar(false)
            setkycSubStatePan(false)
            setSecondT('')
            setSmsTab('')

        }
        if (subType === 'kycall') {
            pageHandler('personal')

            setkycSubStateLocation(true)
            setkycSubStateAAdhaar(true)
            setkycSubStatePan(true)
            setSecondT('')
            setSmsTab('')

        }

    }
    const internalStateHandle = (type, subtype) => {
        setViewPage('similar')

        if (type === 'internal') {
            setinternalState(!internalState)

            setSecondT('')
            if (allSimilarProfileData === '') {
                getdata()
            }
        }
    }
    const getdata = () => {

        let alldata = {
            'userId': allDetail.userId
        }
        let matchByCategory = []
        setSimilarProfileLoader(true)
        getFraudDetailApiNew(alldata,
            (callBack) => {
                similarFilterData('')
                setSimilarProfileLoader(false)
                let last = []
                Object.keys(callBack).length > 0 ?
                    Object.keys(callBack).map(data => {
                        last.push(callBack[data])
                        setallSimilarProfileData(last)
                        setFilterData(last)
                        setFraudData(last)
                        setFilterType('')


                    }) : null

            })

    }
    const phocketTablePagination = (data, type) => {
        if (type == 'back') {
            if (tableMinPagination >= paginationNumber) {
                settableMinPagination(tableMinPagination - paginationNumber)
                settableMaxPagination(tableMaxPagination - paginationNumber)


            }
        } else if (type == 'forward') {
            if (tableMaxPagination < data.length) {
                settableMinPagination(tableMinPagination + paginationNumber)
                settableMaxPagination(tableMaxPagination + paginationNumber)

            }
        }
    }
    const similarFilterData = (data) => {
        setViewPage('similar')
        settableMinPagination(0)
        settableMaxPagination(10)
        setSmsTab('')

        let d = []
        let similarProfileData = allSimilarProfileData
        if (data) {
            setFilterType(data)

            similarProfileData = similarProfileData.filter(row => {
                if (row.matchBy != null) {
                    if (row.matchBy.includes(data)) {

                        d.push(row)
                        setFilterData(d)
                        setFraudData(d)
                        forceUpdate()
                    }
                }
            })
        }
        if (data === '') {
            setFraudData(allSimilarProfileData)
            setFilterType('')
            forceUpdate()
        }

    }
    const getSmsAnalysisStatusData = () => {
        getSmsAnalysisStatus(allDetail.userId, callback => {
            setSmsData(callback)
        })
    }
    const smsTabHandler = () => {
        setsmsAnalysis(!smsAnalysis)


    }
    const getFinbitAnalysisData = () => {
        getFinbitAnalysis(allDetail.userId, callback => {
            setFinbitData(callback)


        })
    }
    const financialTabHandler = () => {
        setfinancialTab(!financialTab)
        setfinBitMainTab(false)
        setSecondT('')

    }
    const openFinbitPage = () => {
        setfinBitMainTab(true)
        setViewPage('finbit')
        setSecondT('')
        setSmsTab('')


    }
    const crifMainTabHandler = () => {
        setViewPage('crif')
        setSecondT('')
        setSmsTab('')

        setcrifMainTab(!crifMainTab)
        if (analysisData === '') {
            getAnalysisData()
        }
    }
    const getCrifAnalysisStatusdata = () => {
        getCrifAnalysisStatus(allDetail.userId, allDetail.loanId, callback => {
            setCrifAnalysisData(callback)
        })
    }
    const getAnalysisData = () => {
        setcrifLoaderData(true)
        fetch(APIS.GET_CRIF_ANALYSIS_DATA_NEW + allDetail.userId + '&loanId=' + allDetail.loanId)
            .then(res => res.json())
            .then(res => {

                setanalysisData(res)
                setcrifLoaderData(false)
                setparseData(JSON.parse(res.progress))
                console.log('res.progressres.progressres.progress')
                setHtmlData(res.progress)
                forceUpdate()
                if (JSON.parse(res.progress) && JSON.parse(res.progress)['INDV-REPORT-FILE'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']) {
                    sortByDisbursedDate(JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']);
                }
            })
        setTimeout(() => {
            setLoader(false)
        }, 2000);

    }
    const regenerateCrif = () => {
        setcrifLoaderData(true)
        fetch(
            APIS.GET_CRIF_DATA +
            allDetail.userId +
            '&regenerateParam=' +
            '1',
            '&loanId=' + allDetail.loanId+ '&empId=' + admin.emailId
        )
            .then(res => res.text())
            .then(res => {
                if (res.responseCode == 0) {
                } else {
                    setuserCrifData(res)
                    setcrifLoaderData(false)
                    setregenerateButton(false)
                }
            })
    }
    const getCrifReport = () => {
        setcrifTab('crifreport')
        if (userCrifData === '') {
            setcrifLoaderData(true)
            fetch(APIS.GET_CRIF_DATA + allDetail.userId + '&regenerateParam=' + regenerate + '&loanId=' + allDetail.loanId + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(res => {
                    if (res.responseCode == 0) {
                    } else {
                        setuserCrifData(res)
                        setcrifLoaderData(false)
                    }
                })
        }
    }
    const smsSuBTabHandler = (type) => {
        setSmsTab(type)
        setSecondT('sms')
        pageHandler('')
        if (type === 'transactionMessages') {
            if (msgData === '') {
                setloadarState(true)
                fetch(APIS.USER_MSG_ANALYSIS + allDetail.userId)
                    .then(res => res.json())
                    .then(res => {

                        setMessageData(res)
                        setloadarState(false)
                    })
            }
        }


    }
    const setCrifSubTab = (type) => {
        setcrifTab(type)
        setViewPage('crif')
        setSmsTab('')


    }
    const saveNotesType = () => {
        if (notesText !== '' && notesText !== undefined && notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, notesText, admin.emailId, notesType, callBack => {
                setNotesPopup(false)
                if (callBack === 'success' && notesType === 'pan') {
                    getNotes(allDetail.loanId, allDetail.userId, 'pan', callBack => {
                        setPanNotes(callBack)
                    })
                }
                if (callBack === 'success' && notesType === 'aadhaar') {
                    getNotes(allDetail.loanId, allDetail.userId, 'aadhaar', callBack => {
                        setAAdharNotesNotes(callBack)
                    })
                }
                if (callBack === 'success' && notesType === 'location') {
                    getNotes(allDetail.loanId, allDetail.userId, 'location', callBack => {
                        setLocationNotes(callBack)
                    })
                }
            })
        }
    }
    const commentChangeHandler = (e, type) => {
        if (e.target.value !== 'add') {
            setNotesText(e.target.value)
        }
        if (e.target.value === 'add') {
            setNotesPopup(true)
            setNotesText(e.target.value)

        }
        setNotesType(type)

    }
    const closepopup = () => {
        setNotesPopup(false)
        setNotesText('')
    }

    const openBankObligation = () => {
        setViewPage('bankObligation')
        setsmsAnalysis(false)
        // setfinancialTab(false)
        setcrifMainTab(false)
        setinternalState(false)
        setkycState(false)
        setSmsTab('')

    }
    const saveUnderWritter = (type) => {


    }
    const getEmployeesRepeat = () => {
        fetch(APIS.GET_ASSIGN_BY_STATUS + '?status=repeat')
            .then(res => res.json())
            .then(res => {
                console.log(res)

            })
    }
    return (
        <>
            {/* {loaderState ?
                <Loader />
                : ""} */}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px dbl-border " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h4 className="text-center" style={{ marginBottom: '10px', marginTop: '10px' }}>Add Note</h4>
                            </div>
                        </div>
                        <div className="row from-group">
                            <div className="col-xs-12 col-xs-12">
                                <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                            </div>
                        </div>
                        <div className="row from-group text-center">
                            <div className="col-xs-12">
                                <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>                         </div>

                        </div>
                    </div>
                </div>
                : ""}
            <div className="phocket-credit container-fluid full-height font-popins" style={{ marginTop: '73px' }}>

                <div className="row full-height" >

                    <div className={menuSize === 'big' ? "col-sm-3 animated slideInLeft" : 'animated slideInRight'} >
                        {menuSize === 'big' ? <div className="credit-icon" onClick={() => menusizeFunc()}><i className="fa fa-arrow-left text-right" style={{ color: '#fff' }}></i> </div> : ""}

                        {menuSize === 'big' ?
                            <>
                                {admin != null && admin.rolelist.indexOf(ROLE.ANALYSIS_DASHBOARD) >= 0 ?

                                    <div className={viewPage === 'dashboard' ? 'main-credit-tab' : ''} style={{ fontSize: '14px', }}>

                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className='activity-circle text-center'>
                                                    <div style={{ marginTop: '-4px' }}> </div>
                                                </div>
                                                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '30px', marginTop: '5px' }}></div>
                                                    {/* <div className='activity-circle text-center' style={{ background: '#bcb4b4' }}>
                                        </div> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-5 " onClick={() => pageHandler('dashboard')} style={{ cursor: 'pointer' }} >
                                                <span>Dashboard</span>
                                            </div>
                                            <div className="col-sm-5 text-right" onClick={() => pageHandler('dashboard')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '12px' }}>&nbsp;&nbsp;&nbsp; Detailed View <i className="fa fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.ANALYSIS_KYC) >= 0 ?

                                    <div className={kycState ? 'main-credit-tab' : ''} style={{ fontSize: '14px' }}>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                {personalData && (personalData.panMatched || personalData.aadharStateMatch || personalData.addressMatch) ?
                                                    <div className="circle-inside-circle">
                                                        <div className="green-div-circle"></div>
                                                    </div> :
                                                    <div className="circle-inside-circle">
                                                        <div className="red-div-circle"></div>
                                                    </div>}
                                                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: kycState ? '10px' : '30px', marginTop: '5px' }}>


                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 " onClick={() => kycStateHandler('kyc', '')} style={{ cursor: 'pointer' }} >
                                                <span>KYC Analysis</span>
                                            </div>
                                            <div className="col-sm-3 text-right" onClick={() => kycStateHandler('kyc', '')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '18px' }}> {kycState ? <i class="fa fa-minus-circle" style={{}} aria-hidden="true"></i> : <i class="fa fa-plus-circle" style={{}} aria-hidden="true"></i>}
                                            </div>

                                        </div>
                                        {kycState ?
                                            <Kyc

                                                kycStateHandler={kycStateHandler}
                                                personalData={personalData}
                                                kycSubStatePan={kycSubStatePan}
                                                kycSubStateAAdhaar={kycSubStateAAdhaar}
                                                kycSubStateLocation={kycSubStateLocation}
                                            />
                                            : ""}
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.Similar_Profile) >= 0 ?

                                    <div className={internalState === true ? 'main-credit-tab' : ''} style={{ fontSize: '14px' }}>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                {matchedSimilarData && matchedSimilarData.length > 0 ?
                                                    <div className="circle-inside-circle">
                                                        <div className="green-div-circle"></div>
                                                    </div> :
                                                    <div className="circle-inside-circle">
                                                        <div className="red-div-circle"></div>
                                                    </div>}
                                                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: internalState ? '10px' : '30px', marginTop: '5px' }}>

                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 " onClick={() => matchedSimilarData && matchedSimilarData.length > 0 ? internalStateHandle('internal', '') : ""} style={{ cursor: 'pointer' }} >
                                                <span>Internal Dedupe</span>
                                            </div>
                                            <div className="col-sm-3 text-right" onClick={() => internalStateHandle('internal', '')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '18px' }}> {internalState ? <i class="fa fa-minus-circle" style={{}} aria-hidden="true"></i> : <i class="fa fa-plus-circle" style={{}} aria-hidden="true"></i>}
                                            </div>

                                        </div>
                                        {internalState ?
                                            <Internal
                                                matchedSimilarData={matchedSimilarData}
                                                similarFilterData={similarFilterData}
                                                filterType={filterType} />
                                            : ""}
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.MESSAGE) >= 0 ?
                                    <div className={smsAnalysis ? 'main-credit-tab' : ''} style={{ fontSize: '14px' }}>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                {smsData && (smsData.overDue || smsData.legal || smsData.totalEmiAmt || smsData.salary || smsData.overDueException) ?
                                                    <div className="circle-inside-circle">
                                                        <div className="green-div-circle"></div>
                                                    </div> :
                                                    <div className="circle-inside-circle">
                                                        <div className="red-div-circle"></div>
                                                    </div>}
                                                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: smsAnalysis ? '10px' : '30px', marginTop: '5px' }}></div>
                                                    {/* <div className='activity-circle text-center' style={{ background: '#bcb4b4' }}>
                                        </div> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-7 " onClick={() => smsTabHandler('personal')} style={{ cursor: 'pointer' }} >

                                                <span>SMS Analysis</span>
                                            </div>
                                            <div className="col-sm-3 text-right" onClick={() => smsTabHandler('persoanl', '')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '18px' }}> {smsAnalysis ? <i class="fa fa-minus-circle" style={{}} aria-hidden="true"></i> : <i class="fa fa-plus-circle" style={{}} aria-hidden="true"></i>}
                                            </div>
                                        </div>
                                        {smsAnalysis
                                            ?
                                            <>
                                                <div className="row"  >
                                                    <div style={{ border: '0.5px solid #E9ECF5', width: '80%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}></div>
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}>

                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-5" onClick={() => smsSuBTabHandler('overdue')} style={{ fontSize: '12px', cursor: 'pointer', color: smsTab === 'overdue' ? '#2B78FF' : '#000', fontWeight: smsTab === 'overdue' ? '700' : '500' }}>
                                                        Overdue SMS</div>
                                                    <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('overdue')}>

                                                        {smsData && smsData.overDue ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 12px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i>
                                                    </div>
                                                </div>

                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-5" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: smsTab === 'legal' ? '#2B78FF' : '#000', fontWeight: smsTab === 'legal' ? '700' : '500' }} onClick={() => smsSuBTabHandler('legal')}>
                                                        Legal SMS</div>

                                                    <div className="col-sm-5 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('legal')}>
                                                        {smsData && smsData.legal ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px ', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '40px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-5" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: smsTab === 'emi' ? '#2B78FF' : '#000', fontWeight: smsTab === 'emi' ? '700' : '500' }} onClick={() => smsSuBTabHandler('emi')}>

                                                        EMI Amount</div>

                                                    <div className="col-sm-5 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('emi')}>
                                                        {smsData && smsData.totalEmiAmt ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 12px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}

                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '80px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-5" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer', color: smsTab === 'salary' ? '#2B78FF' : '#000', fontWeight: smsTab === 'salary' ? '700' : '500' }} onClick={() => smsSuBTabHandler('salary')}>

                                                        Salary</div>

                                                    <div className="col-sm-5 text-right" style={{ fontSize: '12px', marginTop: '20px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('salary')}>
                                                        {smsData && smsData.salary ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}

                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '80px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-5" style={{ fontSize: '12px', cursor: 'pointer', color: smsTab === 'overDueExceptionMsg' ? '#2B78FF' : '#000', fontWeight: smsTab === 'overDueExceptionMsg' ? '700' : '500' }} onClick={() => smsSuBTabHandler('overDueExceptionMsg')}>

                                                        Overdue Exception</div>

                                                    <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('overDueExceptionMsg')}>
                                                        {smsData && smsData.overDueException ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 8px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> : <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}

                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-8" style={{ fontSize: '12px', cursor: 'pointer', color: smsTab === 'transactionMessages' ? '#2B78FF' : '#000', fontWeight: smsTab === 'transactionMessages' ? '700' : '500' }} onClick={() => smsSuBTabHandler('transactionMessages')}>

                                                        Transaction Messages</div>


                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-2">
                                                        <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '60px', marginTop: '-20px' }}></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6" style={{ fontSize: '12px', cursor: 'pointer', color: smsTab === 'viewall' ? '#2B78FF' : '#000', fontWeight: smsTab === 'viewall' ? '700' : '500' }} onClick={() => smsSuBTabHandler('viewall')}>

                                                        Detailed View</div>

                                                    <div className="col-sm-4 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => smsSuBTabHandler('viewall')}>

                                                        <i className="fa fa-chevron-right" style={{ color: '#2B78FF', marginRight: '10px' }}></i></div>
                                                </div>
                                            </>
                                            : ""}
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.FINBIT_DATA) >= 0 ?
                                    <div className={financialTab ? 'main-credit-tab' : ''} style={{ fontSize: '14px' }}>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                {finbitData && finbitData.decision === 'Approved' ?
                                                    <div className="circle-inside-circle">
                                                        <div className="green-div-circle"></div>
                                                    </div> :
                                                    <div className="circle-inside-circle">
                                                        <div className="red-div-circle"></div>
                                                    </div>}
                                                <div><div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: financialTab ? '10px' : '30px', marginTop: '5px' }}></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-7 " onClick={() => financialTabHandler('')} style={{ cursor: 'pointer' }} >
                                                <span>Financial Analysis</span>
                                            </div>
                                            <div className="col-sm-3 text-right" onClick={() => financialTabHandler('')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '18px' }}>  {financialTab ? <i class="fa fa-minus-circle" style={{}} aria-hidden="true"></i> : <i class="fa fa-plus-circle" style={{}} aria-hidden="true"></i>}
                                            </div>
                                        </div>
                                        {financialTab ?
                                            <FinanceAnalysis
                                                viewPage={viewPage}
                                                openBankObligation={openBankObligation}
                                                finbitData={finbitData}
                                                financialTab={financialTab}
                                                openFinbitPage={openFinbitPage}
                                                allDetail={allDetail}
                                                admin={admin}
                                            />
                                            : ""}
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.CRIF_DATA) >= 0 ?

                                    <div className={crifMainTab ? 'main-credit-tab' : ''} style={{ fontSize: '14px' }}>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != '' && (analysisData.rejected || analysisData.finalDecision) ?
                                                    <div className="circle-inside-circle">
                                                        <div className="green-div-circle"></div>
                                                    </div> :
                                                    <div className="circle-inside-circle">
                                                        <div className="red-div-circle"></div>
                                                    </div>}

                                            </div>
                                            <div className="col-sm-7 " onClick={() => crifMainTabHandler('crif')} style={{ cursor: 'pointer' }} >
                                                <span>CRIF Bureau Analysis</span>
                                            </div>
                                            <div className="col-sm-3 text-right" onClick={() => crifMainTabHandler('crif')} style={{ color: '#2B78FF', cursor: 'pointer', fontSize: '18px' }}> {crifMainTab ? <i class="fa fa-minus-circle" style={{}} aria-hidden="true"></i> : <i class="fa fa-plus-circle" style={{}} aria-hidden="true"></i>}
                                            </div>
                                        </div>
                                        {crifMainTab ?
                                            <CrifAnalysis
                                                setcrifTab={setCrifSubTab}
                                                crifData={crifData}
                                                analysisData={analysisData}
                                                getCrifReport={getCrifReport}
                                                crifTab={crifTab}
                                                openFinbitPage={openFinbitPage}
                                            />
                                            : ""}
                                    </div>
                                    : ""}
                                {admin != null && admin.rolelist.indexOf(ROLE.ANALYSIS_CREDIT_POLICY_CHECKLIST) >= 0 ?

                                    <div className={viewPage === 'analysisTab' ? 'main-credit-tab' : ''} style={{ fontSize: '14px', marginBottom: '5px' }}>

                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '30px', marginTop: '' }}></div>

                                                <div className='activity-circle text-center'>
                                                    <div style={{ marginTop: '-4px' }}> </div>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="col-sm-10 " onClick={() => pageHandler('analysisTab')} style={{ cursor: 'pointer', marginTop: '30px' }} >
                                                <span>Credit Policy Checklist</span>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}
                                {admin.emailId !== 'SuperUser' && admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?

                                    <div className={viewPage === 'scoring' ? 'main-credit-tab' : ''} style={{ fontSize: '14px', marginBottom: '20px' }}>

                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '30px', marginTop: '' }}></div>

                                                <div className='activity-circle text-center'>
                                                    <div style={{ marginTop: '-4px' }}> </div>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="col-sm-5 " onClick={() => pageHandler('scoring')} style={{ cursor: 'pointer', marginTop: '30px' }} >
                                                <span>Scoring</span>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}
                                {admin.emailId === 'SuperUser' && admin != null && admin.rolelist.indexOf(ROLE.Cibil_Scoring) >= 0 ?

                                    <div className={viewPage === 'scoring' ? 'main-credit-tab' : ''} style={{ fontSize: '14px', marginBottom: '' }}>

                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '30px', marginTop: '' }}></div>

                                                <div className='activity-circle text-center'>
                                                    <div style={{ marginTop: '-4px' }}> </div>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                            <div className="col-sm-5 " onClick={() => pageHandler('scoring')} style={{ cursor: 'pointer', marginTop: '30px' }} >
                                                <span>Scoring</span>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}
                                {repeatUserData === true && admin != null && admin.rolelist.indexOf(ROLE.CRIF_DATA) >= 0 ?
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div style={{ background: '#DBE9FF', width: '2px', marginLeft: 'auto', marginRight: 'auto', height: '30px', marginTop: '' }}></div>

                                            <div className='activity-circle text-center'>
                                                <div style={{ marginTop: '-4px' }}> </div>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                        <div className="col-sm-10 " onClick={() => pageHandler('repeatAutomation')} style={{ cursor: 'pointer', marginTop: '30px' }} >
                                            <span>Repeat Automation Decision</span>
                                        </div>

                                    </div>
                                    : ""}

                            </>
                            : ""}
                    </div>
                    <div className={menuSize === 'big' ? "col-sm-9 " : 'col-sm-12 easein'} style={{ backgroundColor: viewPage === 'dashboard' ? '#F3F3FA' : '#fff' }}>
                        {menuSize === 'small' ? <div className="credit-icon" style={{ marginLeft: '20px' }} onClick={() => menusizeFunc()}><i className="fa fa-arrow-right text-right" style={{ color: '#fff' }}></i> </div> : ""}

                        {viewPage === 'personal' ?
                            <React.Fragment>
                                {kycState ? <h4 style={{ marginLeft: '20px' }}><strong><img src={ProfileLogo} width='25px' />&nbsp;&nbsp;KYC Analysis</strong></h4> : ""}
                                {kycSubStatePan ?
                                    <div className="col-sm-12" style={{ marginTop: '20px' }}>
                                        <div className="heading-kyc">
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <div style={{ fontSize: '14px', marginTop: '5px' }} className="heading"> PAN Verification</div>
                                                </div>
                                                <div className="col-sm-3 text-right">
                                                    {personalData && personalData.panMatched ? <div className="green-bg-kyc">
                                                        <i className="fa fa-check-circle"></i>&nbsp;Verified via Automation</div> :
                                                        <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                                            <i className="fa fa-times-circle"></i>&nbsp;Unverified via Automation</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 kyc-body" style={{ minHeight: panNotes !== '' && panNotes.notes !== undefined ? '300px' : '230px' }}>
                                            <div className="row">
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Pan Number</label>
                                                    <br />
                                                    {allDetail.panNumber ? <span style={{ fontSize: '14px' }}><strong>{allDetail.panNumber}</strong></span> : 'No Data Found'}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Name as on Pan</label>
                                                    <br />
                                                    {karzaData ? karzaData.namePanAuthentication ? < span style={{ fontSize: '14px' }}><strong>{karzaData ? karzaData.namePanAuthentication : ""}</strong></span> : 'No Data Found' : ""}

                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}> Pan Status</label>
                                                    <br />
                                                    {karzaData && karzaData.status ? <span style={{ fontSize: '14px', color: karzaData.status === 'Active' ? '#4CC78C' : '#FFEFF2' }}><strong>{karzaData ? karzaData.status : ""}</strong></span> : 'No Data Found'}

                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Duplicate</label>
                                                    <br />
                                                    {karzaData && karzaData.duplicate === true ? <strong> <p className='text-center' style={{ color: ' #4CC78C', background: '#FFB828', padding: '1px', width: '84px', borderRadius: '4px' }}>Found</p> </strong> : <strong> <p className='text-center' style={{ color: ' #4CC78C', background: '#E1F6EC', padding: '1px', width: '84px', borderRadius: '4px' }}>Not Found</p></strong>}

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}> DOB Matched</label>
                                                    <br />
                                                    {karzaData && karzaData.dobMatch === true ? <strong > <p style={{ color: ' #4CC78C' }}>True</p> </strong> : <strong > <p style={{ color: ' #FFB828' }}>False</p></strong>}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}> Aadhar Matched</label>
                                                    <br />
                                                    {karzaData && karzaData.aadhaarLinked === true ? <strong > <p style={{ color: ' #4CC78C' }}>True</p> </strong> : <strong > <p style={{ color: ' #FFB828' }}>False</p></strong>}

                                                </div>

                                            </div>

                                            {panNotes && panNotes.notes ?
                                                <>
                                                    <hr />

                                                    <div style={{ background: '#E9ECF5', width: '100%', padding: '10px', borderRadius: '5px' }}>

                                                        <div className="">
                                                            <b>Note:</b>&nbsp;
                                                            {panNotes && panNotes.notes === 'Mark Verified' ?
                                                                <>
                                                                    <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{panNotes ? panNotes.notes : ''}
                                                                </> : ""}

                                                            {panNotes && panNotes.notes === 'Mark Unverified' ?
                                                                <>
                                                                    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{panNotes ? panNotes.notes : ''}
                                                                </>
                                                                : ""}
                                                            {panNotes && panNotes.notes !== 'Mark Unverified' && panNotes.notes !== 'Mark Verified' ?
                                                                <>
                                                                    {panNotes.notes}
                                                                </>
                                                                : ""}

                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                            {admin.emailId !== 'SuperUser' && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'pan')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                            {admin.emailId === 'SuperUser' ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'pan')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                        </div>

                                    </div>
                                    : ""}
                                {kycSubStateAAdhaar ?
                                    <div className="col-sm-12" style={{ marginTop: '20px' }}>
                                        <div className="heading-kyc">
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <div style={{ fontSize: '14px', marginTop: '5px' }} className="heading"> Aadhaar Verification</div>
                                                </div>
                                                <div className="col-sm-3 text-right">
                                                    {personalData && personalData.aadharStateMatch ? <div className="green-bg-kyc">
                                                        <i className="fa fa-check-circle"></i>&nbsp;Verified via Automation</div> :
                                                        <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                                            <i className="fa fa-times-circle"></i>&nbsp;Unverified via Automation</div>
                                                    }                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 kyc-body" style={{ minHeight: aadhaarNotes !== '' && aadhaarNotes.notes !== undefined ? '300px' : '230px' }}>

                                            <div className="row">
                                                <div className="col-sm-3 col-xs-12 remove-rechart-line" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Matched Percentange</label>
                                                    <br />
                                                    {chartDataState.length > 0 ?
                                                        <PieChart width={130} height={130}>
                                                            <Pie
                                                                data={chartDataState}
                                                                cx={50}
                                                                cy={50}
                                                                innerRadius={30}
                                                                outerRadius={50}
                                                                startAngle={450}
                                                                endAngle={90}
                                                                fill="#8884d8"
                                                                paddingAngle={0}
                                                                dataKey="value"
                                                                label={renderCustomizedLabel}

                                                            >
                                                                {chartDataState.map((entry, index) => (
                                                                    <Cell radius={[5, 5, 0, 0]} key={`cell-${index}`} fill={COLORSPINK[index % COLORSPINK.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>

                                                        : "No Data Found"}
                                                    {/* <span style={{ fontSize: '14px' }}><strong>{personalData ? personalData.aadhaarPercentMatch : ""}</strong></span> */}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Address Matched Percentange</label>
                                                    <br />
                                                    {personalData && personalData.aadharAddressMatch ? <span style={{ fontSize: '14px' }}><strong>{personalData ? personalData.aadharAddressMatch : ""}</strong></span> : 'No Data Found'}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Aadhaar Number</label>
                                                    <br />
                                                    {aadhaarXmlData ? aadhaarXmlData.maskedAadhaarNumber ? <span style={{ fontSize: '14px' }}><strong>{aadhaarXmlData ? aadhaarXmlData.maskedAadhaarNumber : ""}</strong></span> : 'No Data Found' : ""}
                                                </div>
                                                <div className="col-sm-3 col-xs-12 text-center" style={{ marginTop: '20px' }}>
                                                    {aadhaarXmlData && aadhaarXmlData.image ? <img width='80px' src={'data:image/png;base64,' + aadhaarXmlData.image} /> : ""}

                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Provided Name</label>
                                                    <br />
                                                    {aadhaarXmlData ? aadhaarXmlData.providedName ? <span style={{ fontSize: '14px' }}><strong>{aadhaarXmlData ? aadhaarXmlData.providedName : ""}</strong></span> : 'No Data Found' : ''}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Date Of Birth</label>
                                                    <br />
                                                    {aadhaarXmlData ? aadhaarXmlData.dob ? <span style={{ fontSize: '14px' }}><strong>{aadhaarXmlData ? aadhaarXmlData.dob : ""}</strong></span> : 'No Data Found' : ""}
                                                </div>

                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}> Name</label>
                                                    <br />
                                                    {aadhaarXmlData ? aadhaarXmlData.name ? <span style={{ fontSize: '14px' }}><strong>{aadhaarXmlData ? aadhaarXmlData.name : ""}</strong></span> : 'No Data Found' : ''}
                                                </div>
                                                <div className="col-sm-3 col-xs-12 text-center">
                                                    <button className='btn btn-primary' style={{ background: '#2B78FF', color: '#fff' }} onClick={() => downloadFile("download.zip", aadhaarXmlData.file)}>Download Xml</button>

                                                </div>



                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Father Name</label>
                                                    <br />
                                                    {aadhaarXmlData ? aadhaarXmlData.fatherName ? <span style={{ fontSize: '14px' }}><strong>{aadhaarXmlData ? aadhaarXmlData.fatherName : ""}</strong></span> : 'No Data Found' : ''}
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                    <label style={{ color: 'dimgray', fontSize: '12px' }}>Status</label>
                                                    <br />
                                                    {aadhaarXmlData && aadhaarXmlData.status ? <span style={{ fontSize: '14px', color: aadhaarXmlData.status === true ? 'rgb(76, 199, 140)' : '#FFEFF2' }}><strong>{aadhaarXmlData ? aadhaarXmlData.status === true ? 'Active' : '' : "N/A"}</strong></span> : "No Data Found"}

                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                </div>
                                                <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                </div>
                                            </div>

                                            {aadhaarNotes ?
                                                <>
                                                    <hr />
                                                    <div style={{ background: '#E9ECF5', width: '100%', padding: '10px', borderRadius: '5px' }}>
                                                        <div className="">
                                                            <b>Note:</b>&nbsp;
                                                            {aadhaarNotes && aadhaarNotes.notes === 'Mark Verified' ?
                                                                <>
                                                                    <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{aadhaarNotes ? aadhaarNotes.notes : ''}
                                                                </> : ""}

                                                            {aadhaarNotes && aadhaarNotes.notes === 'Mark Unverified' ?
                                                                <>
                                                                    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{aadhaarNotes ? aadhaarNotes.notes : ''}
                                                                </>
                                                                : ""}
                                                            {aadhaarNotes && aadhaarNotes.notes !== 'Mark Unverified' && aadhaarNotes.notes !== 'Mark Verified' ?
                                                                <>
                                                                    {aadhaarNotes.notes}
                                                                </>
                                                                : ""}

                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                            {admin.emailId !== 'SuperUser' && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                                                <>
                                                    <div className="row" style={{ marginBottom: '20px' }}>
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'aadhaar')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                            {admin.emailId === 'SuperUser' ?
                                                <>
                                                    <div className="row" style={{ marginBottom: '20px' }}>
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'aadhaar')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                        </div>
                                    </div>
                                    : ""}
                                {kycSubStateLocation ?
                                    <>
                                        <div className="col-sm-12" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <div className="heading-kyc">
                                                <div className="row">
                                                    <div className="col-sm-9" >
                                                        <div style={{ fontSize: '14px', marginTop: '5px' }} className="heading"> Location Verification</div>
                                                    </div>
                                                    <div className="col-sm-3 text-right">
                                                        {personalData && personalData.addressMatch ? <div className="green-bg-kyc">
                                                            <i className="fa fa-check-circle"></i>&nbsp;Verified via Automation</div> :
                                                            <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                                                <i className="fa fa-times-circle"></i>&nbsp;Unverified via Automation</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 kyc-body" style={{ minHeight: locationNotes !== '' && locationNotes.notes !== undefined ? '300px' : '230px' }}>
                                                <div className="row">
                                                    <div className="col-sm-3 col-xs-12 remove-rechart-line" style={{ marginTop: '20px' }}>
                                                        <label style={{ color: 'dimgray', fontSize: '12px' }}>Crif Address Match Percentage</label>
                                                        <br />
                                                        {chartDataState ?
                                                            <PieChart width={130} height={130}>
                                                                <Pie
                                                                    data={chartDataStateLocation}
                                                                    cx={50}
                                                                    cy={50}
                                                                    innerRadius={30}
                                                                    outerRadius={50}
                                                                    startAngle={450}
                                                                    endAngle={90}
                                                                    fill="#8884d8"
                                                                    paddingAngle={0}
                                                                    dataKey="value"
                                                                    label={renderCustomizedLabelLocation}
                                                                    l
                                                                >
                                                                    {chartDataState.map((entry, index) => (
                                                                        <Cell radius={[5, 5, 0, 0]} key={`cell-${index}`} fill={ColorGreen[index % ColorGreen.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>

                                                            : "No Data Found"}
                                                    </div>
                                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                        <label style={{ color: 'dimgray', fontSize: '12px' }}> Aadhar State Match</label>
                                                        <br />
                                                        {aadhaarXmlData && aadhaarXmlData.aadharStateMatch === true ? <span style={{ color: '#E1F6EC', fontWeight: '700' }}>True</span> : <span style={{ color: '#FF5C5D', fontWeight: '700' }}>False</span>}

                                                    </div>
                                                    <div className="col-sm-3 col-xs-12" style={{ marginTop: '20px' }}>
                                                        <label style={{ color: 'dimgray', fontSize: '12px' }}> Crif Pincode Match</label>
                                                        <br />
                                                        {personalData && personalData.crifPincodeMatch === true ? <span style={{ color: '#4CC78C', fontWeight: '700' }}>True</span> : <span style={{ color: '#FF5C5D', fontWeight: '700' }}>False</span>}

                                                    </div>

                                                </div>

                                                {locationNotes ?
                                                    <>
                                                        <hr />
                                                        <div style={{ background: '#E9ECF5', width: '100%', padding: '10px', borderRadius: '5px' }}>
                                                            <div className="">
                                                                <b>Note:</b>&nbsp;
                                                                {locationNotes && locationNotes.notes === 'Mark Verified' ?
                                                                    <>
                                                                        <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{locationNotes ? locationNotes.notes : ''}
                                                                    </> : ""}

                                                                {locationNotes && locationNotes.notes === 'Mark Unverified' ?
                                                                    <>
                                                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{locationNotes ? locationNotes.notes : ''}
                                                                    </>
                                                                    : ""}
                                                                {locationNotes && locationNotes.notes !== 'Mark Unverified' && locationNotes.notes !== 'Mark Verified' ?
                                                                    <>
                                                                        {locationNotes.notes}
                                                                    </>
                                                                    : ""}

                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""}
                                                {admin.emailId !== 'SuperUser' && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?

                                                    <div className="row" style={{ marginBottom: '20px' }}>
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'location')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {admin.emailId === 'SuperUser' ?

                                                    <div className="row" style={{ marginBottom: '20px' }}>
                                                        <div className="col-sm-3" >
                                                            <select className="form-control" onChange={(e) => commentChangeHandler(e, 'location')} style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                                                <option value={''}>Select Option</option>
                                                                <option value={'Mark Verified'}>Mark Verified</option>
                                                                <option value={'Mark Unverified'}>Mark Unverified</option>
                                                                <option value={'add'}>Add Note</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-12">
                                                            <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                                        </div>
                                                    </div>
                                                    : ""}

                                            </div>

                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <ApplicantLocation allDetail={allDetail} />
                                        </div>
                                    </>
                                    : ""}
                            </React.Fragment>
                            : ""}
                        {viewPage === 'internal' ?
                            <React.Fragment>
                                <Credit userId={allDetail.userId} loanId={allDetail.loanId} />
                            </React.Fragment>
                            : ""}
                        {viewPage === 'dashboard' ?
                            <React.Fragment>
                                <DashboardUi personalData={personalData} admin={admin} userDetails={userDetails} allDetail={allDetail} userId={allDetail.userId} menuSize={menuSize} loanId={allDetail.loanId} />
                            </React.Fragment>
                            : ""}
                        {viewPage === 'similar' ?
                            <FraudTable
                                similarProfileLoader={similarProfileLoader}
                                phocketTablePagination={phocketTablePagination}
                                filterType={filterType}
                                fraudData={fraudData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                            />
                            : ""}
                        {viewPage === 'finbit' ?
                            < FinBit userId={allDetail.userId}
                                finbitData={finbitData}
                                allDetail={allDetail}
                                admin={admin}

                            />
                            : ""}
                        {viewPage === 'crif' && crifMainTab ?
                            <CrifNew
                            htmlData={htmlData}

                            allDetail={allDetail}
                                admin={admin}
                                sortedData={sortedData}
                                crifLoaderData={crifLoaderData}
                                analysisData={analysisData} crifTab={crifTab} crifData={crifData}
                                userCrifData={userCrifData} regenerateCrif={regenerateCrif}
                                parseData={parseData}
                            />
                            : ""}
                        {smsTab === 'salary' ?
                            <SmsTable
                                smsData={smsData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}


                            />
                            : ""}
                        {smsTab === 'legal' ?
                            <Legalsms
                                smsData={smsData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}
                            />
                            : ""}
                        {smsTab === 'overdue' ?
                            <OverdueSms
                                smsData={smsData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}
                            />
                            : ""}
                        {smsTab === 'overDueExceptionMsg' ?
                            <OverDueException
                                smsData={smsData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}
                            />
                            : ""}

                        {smsTab === 'emi' ?
                            <EmismsTable
                                smsData={smsData}
                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}
                            />
                            : ""}
                        {smsTab === 'viewall' ?
                            <RawMsg allDetail={allDetail} />
                            : ""}
                        {smsTab === 'transactionMessages' ?
                            <Messages loadarState={loadarState}
                                msgData={msgData} />
                            : ""}
                        {viewPage === 'bankObligation' ?
                            <BankObligationTable

                                tableMaxPagination={tableMaxPagination}
                                tableMinPagination={tableMinPagination}
                                phocketTablePagination={phocketTablePagination}
                                msgData={finbitData.msgDataOblgiation} />
                            : ""}
                        {viewPage === 'analysisTab' ?
                            <PolicyDetails allDetail={allDetail} admin={admin} />
                            : ""}
                        {viewPage === 'scoring' ?
                            <ScoreNew admin={admin} allDetail={allDetail} />
                            : ""}
                        {viewPage === 'repeatAutomation' ?
                            <Repeat allDetail={allDetail} />
                            : ""}


                    </div>
                </div>
            </div >

        </>
    )
}
export default Analysis