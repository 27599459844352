
import moment from "moment";
import React from "react";
import { getOverallparticularLead } from "../maketingComponents/actioncreator";
import { useState } from "react";
import { firstDateOfMonth, formatDate, lastDateOfMonth, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import { useEffect } from "react";
import LoanApplyContainerDis from '../../Admin/ApplicationLeads/disburseDate.container'
function LeadParticulars({ admin, newDataBackground, allStatus }) {
    const [pageNo, setpageNo] = useState(1);
    const [dateType, setdateType] = useState('disburseDate');
    const [loanDate, setLoanDate] = useState('');
    const [viewTab, setViewTab] = useState('particular');

    const [noOfEntry, setnoOfEntry] = useState(10);
    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
        minDate: monthStartDate(),
    });
    const [data, setLeadParticularData] = useState('')
    const [today, setToday] = useState(todayDate())
    useEffect(() => {
        particularleadData()
    }, [])
    const particularleadData = () => {
        getOverallparticularLead(
            payload,
            pageNo,
            noOfEntry,
            dateType,
            (callBack) => {
                setLeadParticularData(callBack);
            },
            (error) => {
                console.log(error);
            }
        );
    }
    const handleChange = (e) => {

        if (e.target.name === "fromDate") {
            setPayload({
                ...payload,
                fromDate:
                    e.target.type === "date"
                        ? formatDate(e.target.value)
                        : e.target.value,
                toDate: payload.toDate,
            });
        } else {
            setPayload({
                ...payload,
                [e.target.name]:
                    e.target.type === "date"
                        ? formatDate(e.target.value)
                        : e.target.value,
                fromDate: payload.fromDate,
            });
        }
    };
    const getData = () => {
        particularleadData();
    }
    const details = (date) => {
console.log(date)
        setLoanDate(date)
        // setTimeout(() => {
            setViewTab('leads')
        // }, 1000);
    }
    return (
        <>
            {viewTab === 'particular' ?
                <div style={{}}>
                    <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px', minHeight: '750px' }}>
                        <div className="bg-white p-4 " style={{ padding: '15px' }}>
                            <h5 style={{ color: '#1d3557', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Lead Particulars</strong></h5>
                            <div className="row">
                                <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                                    From
                                    <input
                                        type="date"
                                        id="startDate"
                                        placeholder="DD/MM/YY"
                                        name="fromDate"
                                        value={reverseFormat(payload.fromDate)}
                                        onChange={handleChange}
                                        className=" form-control"
                                        style={{
                                            border: "1px solid gray",
                                            borderRadius: "6px",
                                            padding: "10px",
                                        }}
                                    />
                                </div>

                                <div className="col-sm-2 col-xs-12">
                                    <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                        to
                                    </div>

                                    <input
                                        type="date"
                                        id="endDate"
                                        name="toDate"
                                        max={reverseFormat(subtractOneDay(today))} // Assuming reverseFormat gives the correct date format
                                        value={reverseFormat(subtractOneDay(payload.toDate))}
                                        onChange={handleChange}
                                        placeholder="DD/MM/YY"
                                        className="form-control"
                                        style={{
                                            border: "1px solid gray",
                                            borderRadius: "6px",
                                            padding: "10px",
                                        }}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                        Date Type
                                        <select className="form-control" value={dateType} onChange={(e) => setdateType(e.target.value)}>
                                            <option value={''}>Select Value</option>
                                            <option value={'disburseDate'}>Disburse</option>
                                            <option value={'apply'}>Apply</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-xs-12">
                                    <button
                                        className="btn btn-primary mt-4 "
                                        style={{
                                            color: "#006c67",
                                            borderColor: "#006c67",
                                            backgroundColor: "white",
                                            marginTop: "23px",
                                            padding: "10px",
                                            borderRadius: "5px",
                                        }}
                                        onClick={() => getData()}
                                    >
                                        Apply Filter
                                    </button>
                                </div>
                            </div>


                            <hr />
                            {dateType === 'apply' ?
                                <div className='credit-table' style={{ fontSize: '12px' }} >
                                    <table>
                                        <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                            <tr className="thead-class">
                                                <th className="">
                                                    <b>
                                                        <b>Date</b>
                                                    </b>
                                                </th>
                                                <th className="">
                                                    <b>Fresh Leads</b>
                                                </th>
                                                <th className="">
                                                    <b>Fresh Approval</b>
                                                </th>
                                                <th className="">
                                                    <b>Fresh Disbursement</b>
                                                </th>

                                                <th className="">
                                                    <b>Total Spent</b>
                                                </th>
                                                <th className="">
                                                    <b>Repeat Leads</b>
                                                </th>
                                                <th className="">
                                                    <b>Repeat Approval</b>
                                                </th>
                                                <th className="">
                                                    <b>Repeat Disbursement</b>
                                                </th>
                                                <th className="">
                                                    <b>Total Leads</b>
                                                </th>
                                                <th className="">
                                                    <b>Amount Disbursed</b>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(data) && data.length > 0 ?
                                                data.map((data1, i) => {
                                                    return (
                                                        <tr style={{ borderBottom: "none", fontWeight: '400' }}>

                                                            <td scope="col">
                                                                {data1.loanApplyDate ? moment(data1.loanApplyDate).format('DD-MM-YYYY') : ''}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totFreshCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totFreshApprovalCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totFreshDisbursalAmount}
                                                            </td>

                                                            <td scope="col">
                                                                {data1.spent}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totRepeatCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totRepeatApprovalCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totRepeatDisbursalAmount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totalCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totDisburseAmount}
                                                            </td>


                                                        </tr>
                                                    )
                                                }) : ""}

                                        </tbody>
                                    </table>
                                </div>
                                : ""}
                            {dateType === 'disburseDate' ?
                                <div className='credit-table' style={{ fontSize: '12px' }} >
                                    <table>
                                        <thead style={{ background: '#b9d8f9', color: '#000' }}>
                                            <tr className="thead-class">
                                                <th>Leads</th>
                                                <th className="">
                                                    <b>
                                                        <b> Disbursement Date</b>
                                                    </b>
                                                </th>
                                                <th className="">
                                                    <b>Fresh Count</b>
                                                </th>
                                                <th className="">
                                                    <b>Fresh Amount </b>
                                                </th>
                                                <th className="">
                                                    <b>Repeat Count</b>
                                                </th>

                                                <th className="">
                                                    <b>Repeat Amount</b>
                                                </th>
                                                <th className="">
                                                    <b>Total Count</b>
                                                </th>
                                                <th className="">
                                                    <b>Total Amount </b>
                                                </th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(data) && data.length > 0 ?
                                                data.map((data1, i) => {
                                                    return (
                                                        <tr style={{ borderBottom: "none", fontWeight: '400' }}>
                                                            <td scope="col">
                                                                <i className="fa fa-info" style={{ cursor: 'pointer' }} onClick={() => details(moment(data1.loanDisbursementDate).format('DD-MM-YYYY'))}></i>
                                                            </td>
                                                            <td scope="col">
                                                                {data1.loanDisbursementDate ? moment(data1.loanDisbursementDate).format('DD-MM-YYYY') : ''}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totDisburseAmount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totFreshDisbursalCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totFreshDisbursalAmount}
                                                            </td>

                                                            <td scope="col">
                                                                {data1.totRepeatDisbursalCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totDisburseCount}
                                                            </td>
                                                            <td scope="col">
                                                                {data1.totRepeatDisbursalAmount}
                                                            </td>



                                                        </tr>
                                                    )
                                                }) : ""}

                                        </tbody>
                                    </table>
                                </div> : ""}
                        </div>
                    </div>
                </div>
                : ""}
            {viewTab === 'leads' ?
                <div className="background-blur">

                    <div className="popup-main  container-fluid  " style={{ padding: '', borderRadius: '10px',width:'98%',overflow:'scroll',height:'90%',maxWidth:'95%' }}>
                        <i onClick={() => setViewTab('particular')} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red',cursor:'pointer' }} className="fa fa-times-circle"></i>
                        <LoanApplyContainerDis loanDate={loanDate} admin={admin} allStatus={allStatus} newDataBackground={newDataBackground} />
                    </div>
                </div>
                : ""}
        </>
    );
}

export default LeadParticulars;
