import React, { useEffect, useState } from "react";

import { Pagination } from "@mui/material";
import { formatDate, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import { getBuisnessFromAgreegatorsApi } from "../maketingComponents/actioncreator";
import Skeleton from "react-loading-skeleton";

const BuisnessAggregator = () => {
    const [loaderState, setLoaderState] = useState(false)
    const [count, setCount] = useState(0);
    const [pageNo, setPage] = useState(1);
    const [noOfEntry, setnoOfEntry] = useState(10);
    const [objectData, setObjectData] = useState('');
    const [payload, setPayload] = useState({
        filter: "Daily",
        fromDate: monthStartDate(),
        toDate: todayDate(),
    });
    const [today, settoday] = useState(todayDate());

    const handleChange = (e) => {
        console.log(e.target.type);
        console.log(formatDate(e.target.value));
        setPayload({
            ...payload,
            [e.target.name]:
                e.target.type === "date" ? formatDate(e.target.value) : e.target.value,
        });

        console.log(payload);
    };
    const getData = () => {
        setLoaderState(true)
        getBuisnessFromAgreegatorsApi(payload, pageNo, noOfEntry, callback => {
            setObjectData(callback)
            setLoaderState(false)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px', minHeight: '600px' }}>
                <div className="bg-white p-4 " style={{ padding: '15px' }}>
                    <h5 style={{ color: '#1d3557', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Business from Aggregators</strong></h5>

                    <div className="row">
                        {/* <div className="col-sm-2 col-xs-12">
          <img src={Filter} width={20} height={20} alt="" />
          <span className="ms-3 filterHeaders">Filter</span>
          <select
            name=""
            id=""
            value={payload.filter}
            className="form-select"
            style={{ width: '100%', padding: '7px' }}
          >
            <option value="daily">Daily</option>
          </select>
        </div> */}
                        {/* <div style={{ position: 'absolute', marginLeft: '16.5%', marginTop: '32px' }}>::</div> */}
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                            From
                            <input
                                type="date"
                                id="startDate"
                                placeholder="DD/MM/YY"
                                name="fromDate"
                                value={reverseFormat(payload.fromDate)}
                                onChange={handleChange}
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />

                        </div>
                        {/* <div style={{ position: 'absolute', marginLeft: '33%', marginTop: '32px' }}>-</div> */}

                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '5px' }}>
                            <div className="filterHeaders">to</div>
                            <input
                                type="date"
                                id="endDate"
                                name="toDate"
                                max={reverseFormat(subtractOneDay(today))}
                                value={reverseFormat(subtractOneDay(payload.toDate))}
                                onChange={handleChange}
                                placeholder="DD/MM/YY"
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <button
                                className="btn btn-primary mt-4 "
                                style={{
                                    color: "#006c67",
                                    borderColor: "#006c67",
                                    backgroundColor: "white",
                                    marginTop: '27px',
                                    padding: '5px',
                                    borderRadius: '5px'
                                }}
                                onClick={() => getData()}

                            >
                                Apply Filter
                            </button>

                        </div>
                    </div>
                    <hr />


                    <div className='credit-table'>
                        <table>
                            <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000' }}>
                                <tr className="thead-class">
                                    <th className="">Aggregator Name</th>
                                    <th className="">Fresh Leads</th>
                                    <th className="">Fresh Approvals</th>
                                    <th className="">Fresh Disbursement</th>
                                    <th className="">CPA</th>
                                    <th className="">Total Cost</th>
                                    <th className="">Repeat Leads</th>
                                    <th className="">Repeat Approvals</th>
                                    <th className="">Repeat Disbursements</th>
                                    <th className="">Total Leads</th>
                                    <th className="">Amount Disbursement</th>
                                </tr>
                            </thead>
                            <tbody>

                                {objectData !== '' && objectData.map((data, i) => {
                                    return (
                                        <tr style={{ borderBottom: "none" }}>
                                            <td scope="col">{data.aggregatorName} </td>
                                            <td scope="col">{data.freshLeads} </td>
                                            <td scope="col">{data.freshApprovals} </td>
                                            <td scope="col">{data.freshDisbursement} </td>
                                            <td scope="col">{data.cpa} </td>
                                            <td scope="col">{data.totalCost} </td>
                                            <td scope="col">{data.repeatLeads} </td>
                                            <td scope="col">{data.repeatApprovals} </td>
                                            <td scope="col">{data.repeatDisbursement} </td>
                                            <td scope="col">{data.totalLeads} </td>
                                            <td scope="col">{data.amountDisbursement} </td>

                                        </tr>
                                    )
                                })}
                                {loaderState ?
                                    <>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>   <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                    </>
                                    : ""}



                            </tbody>
                        </table>
                    </div>
                    {count > 1 ?
                        <div className="row">

                            <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'end' }} >
                                <b > Page :</b>
                            </div>
                            <div className="col-sm-1">
                                <select className="form-control">
                                    <option>1</option>
                                </select>
                            </div>
                            <div className='col-sm-1 ' style={{ marginTop: '14px', textAlign: 'start' }} >
                                <b > of &nbsp; 0</b>
                            </div>
                            <div className="col-sm-5"></div>

                            <div className="col-sm-4"> <div style={{ float: 'right', marginTop: '10px', marginBottom: '10px', marginRight: '40px' }}>
                                <Pagination count={10} color="primary"
                                    shape="circle"
                                // onChange={changeHandler}
                                />
                            </div></div>
                        </div>
                        : ""}
                </div>

            </div>
        </>

    );
}

export default BuisnessAggregator;
