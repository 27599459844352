import React, { useState } from "react";
import ExpensesAdd from "./add";
import ExpensesList from "./list";
const MainExpenses = () => {
    const [viewType, setviewType] = useState('list')
    const [editData, seteditData] = useState('')
    const [ActiveMonthFilter, setActiveMonthFilter] = useState('3month')

    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }
    const ChangeScreen=(type)=>{
        seteditData('')
        setviewType(type)
    }
    const handlemonthFilter = (type) => {
        setActiveMonthFilter(type)
    }
    return (
        <div className="container-fluid">
            <div style={{ display: 'flex', textAlign: 'center' ,marginTop:'40px',marginBottom:'-30px'}}>
                <div onClick={() => ChangeScreen('list')} style={{ color: viewType === 'list' ? '#fff' : '#000', background: viewType === 'list' ? '#006c67' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-list" ></i></div>&nbsp;&nbsp;
                <div onClick={() => ChangeScreen('add')} style={{ color: viewType === 'add' ? '#fff' : '#000', background: viewType === 'add' ? '#006c67' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-plus" ></i></div>

            </div>
            {viewType === 'list' ?
            <div className="customfilterbtn">
                <div className="inputgrp" style={{ marginTop: '60px' }}>
                    <label className='inputlabel'>Month Filter</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '3month' ? 'active' : ''}`} onClick={() => handlemonthFilter('3month')} >last 3 Month</button>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '6month' ? 'active' : ''}`} onClick={() => handlemonthFilter('6month')} >last 6 Month</button>
                        <button className={`filterbtn2 ${ActiveMonthFilter === '1year' ? 'active' : ''}`} onClick={() => handlemonthFilter('1year')} >last 1 Year</button>
                    </div>
                </div>
            </div>
            :''}
            {viewType === 'list' ?
                <ExpensesList  editHandler={editHandler} ActiveMonthFilter={ActiveMonthFilter}/>
                : ""}
            {viewType === 'add' ?
                <ExpensesAdd editData={editData} />

                : ""}
        </div>
    )
}
export default MainExpenses