import React, { useEffect, useState } from "react";
import { getEmailKeyData } from "../AdminActionCreator.component";
import NoDataimg from '../../../../images/nodata.png'
const EmaiLKey = ({emailData,handlebtnclick}) => {
  
   
    return (
        <div className="container">
            {emailData && emailData?.length > 0 ?
            <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px', marginBottom: '30px' }}>
                <table style={{ marginBottom: '10px' }}>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Key</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emailData ? emailData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.email}</td>
                                <td>{data.key}</td>
                                <td>
                                    <div className="edits">
                                        <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" style={{cursor:'pointer'}} onClick={() => handlebtnclick('edit', data)}></i>&nbsp;&nbsp;
                                        <i className="fa fa-trash-o fa-lg" aria-hidden="true" style={{cursor:'pointer'}} onClick={() => handlebtnclick('delete', data)}></i>
                                    </div>
                                </td>
                            </tr>
                        )) : ""}
                    </tbody>
                </table>

            </div>
             :
             <div className="text-center">
                 <img src={NoDataimg} />
             </div>
         }
        </div>
    )
}
export default EmaiLKey