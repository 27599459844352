import React from 'react'
import moment from 'moment';
import ChequeDepositeAlert from '../../../presentationals/MailTemplets/ChequeDepositeAlert'
import StartingLegalNotice from '../../../presentationals/MailTemplets/StartingLegalNotice'
import RevisedRepaymentAmount from '../../../presentationals/MailTemplets/RevisedRepaymentAmount'
import ChequeBounced from '../../../presentationals/MailTemplets/ChequeBounced'
import NachBounced from '../../../presentationals/MailTemplets/NachBounced'
import LoanDueWithoutNach from '../../MailTemplets/LoanDueWithoutNach'
import AutoDebitWithNach from '../../MailTemplets/AutoDebitWithNach'
import EmiAutoDebitWithNach from '../../MailTemplets/EmiAutoDebitWithNach'
import EmiLoanDueWithoutNach from '../../MailTemplets/EmiLoanDueWithoutNach'
import LoanUnderDefault from '../../MailTemplets/LoanUnderDefault'
import DefaultNautification from '../../MailTemplets/defaultnatuification';

const RecoveryMail = ({ recoveryMailChange, recoveryMailHandler, mailTempleteClick,
    allDetail, subject, mailViewState, collectionType }) => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xs-5">
                    <h3 className=" text-center blue-text">Templates</h3>
                    {collectionType == 'recovery' ?
                        <ol>
                            <li>
                                <input type="radio" value="Credfin: Cheque Deposit Alert." name="communicationMail" onClick={e => mailTempleteClick(e, 'chequeDepositeAlert')} /> &nbsp;&nbsp;
                                <span><strong>Credfin: Cheque Deposit Alert.</strong></span>
                            </li>
                            <li>
                                <input type="radio" value="Credfin : Starting process to Issue Legal Notice." name="communicationMail" onClick={e => mailTempleteClick(e, 'startingLegalNotice')} /> &nbsp;&nbsp;
                                <span><strong>Credfin : Starting process to Issue Legal Notice.</strong></span>
                            </li>
                            <li>
                                <input type="radio" value={'Credfin: Revised repayment amount as on ' + moment(new Date()).format('DD-MM-YYYY') + '.'} name="communicationMail" onClick={e => mailTempleteClick(e, 'revisedRepaymentAmount')} /> &nbsp;&nbsp;
                                <span><strong>Credfin: Revised repayment amount as on {moment(new Date()).format('DD-MM-YYYY')}.</strong></span>
                            </li>
                            <li>
                                <input type="radio" value="Credfin: Cheque Bounced." name="communicationMail" onClick={e => mailTempleteClick(e, 'chequeBounced')} /> &nbsp;&nbsp;
                                <span><strong>Credfin: Cheque Bounced.</strong></span>
                            </li>
                            <li>
                                <input type="radio" value="Credfin: NACH Bounced." name="communicationMail" onClick={e => mailTempleteClick(e, 'nachBounced')} /> &nbsp;&nbsp;
                                <span><strong>Credfin: NACH Bounced.</strong></span>
                            </li>
                            <li>
                                <input type="radio" value="Credfin: Loan Under Default" name="communicationMail" onClick={e => mailTempleteClick(e, 'loanUnderDefault')} /> &nbsp;&nbsp;
                                <span><strong>Credfin: Loan Under Default.</strong></span>
                            </li>
                        </ol>
                        : collectionType == 'disburse' ?
                            <ol>
                                <li>
                                    <input type="radio" value="Reminder: Credfin Loan Due." name="communicationMail" onClick={e => mailTempleteClick(e, 'loanDueWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Credfin Loan Due (without NACH).</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value="Reminder: Auto debit of your Credfin Loan Repayment amount." name="communicationMail" onClick={e => mailTempleteClick(e, 'autoDebitWithNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Auto debit of your Credfin Loan Repayment amount (with NACH).</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value="Reminder: Credfin Loan EMI Due." name="communicationMail" onClick={e => mailTempleteClick(e, 'emiLoanDueWithoutNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Credfin Loan EMI Due (without NACH).</strong></span>
                                </li>
                                <li>
                                    <input type="radio" value="Reminder: Auto debit of your Credfin Loan Repayment amount." name="communicationMail" onClick={e => mailTempleteClick(e, 'emiAutoDebitWithNach')} /> &nbsp;&nbsp;
                        <span><strong>Reminder: Auto debit of your Credfin Loan EMI (with NACH).</strong></span>
                                </li>
                            </ol>
                            : null}
                </div>
                <div className="row form-group">
                    <div className="col-xs-12">
                        <input type="text" className="form-control" placeholder="Subject" value={subject} onChange={e => recoveryMailChange(e, 'subject')}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="richText" contentEditable="true" id="recoveryMailBody" >
                            {mailViewState == 'chequeDepositeAlert' ?
                                <ChequeDepositeAlert
                                    allDetail={allDetail}
                                />
                                : mailViewState == 'defaultNautification' ?
                                    <DefaultNautification
                                        allDetail={allDetail}
                                    />
                                    : mailViewState == 'startingLegalNotice' ?
                                        <StartingLegalNotice
                                            allDetail={allDetail}
                                        />
                                        : mailViewState == 'revisedRepaymentAmount' ?
                                            <RevisedRepaymentAmount
                                                allDetail={allDetail}
                                            />
                                            : mailViewState == 'chequeBounced' ?
                                                <ChequeBounced
                                                    allDetail={allDetail}
                                                />
                                                : mailViewState == 'nachBounced' ?
                                                    <NachBounced
                                                        allDetail={allDetail}
                                                    />
                                                    : mailViewState == 'loanUnderDefault' ?
                                                        <LoanUnderDefault
                                                            allDetail={allDetail}
                                                        />
                                                        : mailViewState == 'loanDueWithoutNach' ?
                                                            <LoanDueWithoutNach
                                                                allDetail={allDetail}
                                                            />
                                                            : mailViewState == 'autoDebitWithNach' ?
                                                                <AutoDebitWithNach
                                                                    allDetail={allDetail}
                                                                />
                                                                : mailViewState == 'emiAutoDebitWithNach' ?
                                                                    <EmiAutoDebitWithNach
                                                                        allDetail={allDetail}
                                                                    />
                                                                    : mailViewState == 'emiLoanDueWithoutNach' ?
                                                                        <EmiLoanDueWithoutNach
                                                                            allDetail={allDetail}
                                                                        />
                                                                        : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <button className="profile-save-btn center-block" onClick={e => recoveryMailHandler()}>SEND</button>
                    </div>
                </div>
            </div>
        </div>

)

export default RecoveryMail;