import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket : Security Cheque Clarification

const SecurityChequeClarification = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Greetings for the day!</div>
        <br />
        <div>We have entered into a 3 year agreement with you and during this period you can avail loan anytime from us without any physical process.</div>
        <br />
        <div>We have defined an overall limit of {allDetail != null && allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO.netTakeHomeSalary != null ? allDetail.employmentDetailsPO.netTakeHomeSalary : ""} equivalent of 100% of your salary.</div>
        <br />
        <div>As your credit history builds with us we will increase your loan limit from current amount to {allDetail != null && allDetail.employmentDetailsPO != null && allDetail.employmentDetailsPO.netTakeHomeSalary != null ? allDetail.employmentDetailsPO.netTakeHomeSalary : ""}.</div>
        <br />
        <div>We collect cheque only for security purposes. This cheque needs to be undated &amp; in favour of Citra Financials Pvt Ltd.</div>
        <br />
        <div>If you still have any concern our finance team can get in touch with you.</div>
        <br />
        <div>Best Regards,</div>
        <div>Team Credfin</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default SecurityChequeClarification