import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'


const DefaultNautification = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Your Credfin Loan is under Default for over 20 days now.</div>
        <div>We would need to report the "DEFAULT" to the Credit Reporting Agencies which may have a Negative Impact on your Credit Score and on your Future Loan Applications.</div>
        <div>You might be contacted by the Recovery Executive who will further discuss and share the schedule for visit to your Official /Home Address etc.</div>
        <br/><div>Kindly make the payment on immediate priority.</div>
        <br />
        {/* <div>Get in touch with us: <a href='tel:+918010700600'>+91-8010700600</a> or <a href='mailto:collections@phocket.in'>collections@phocket.in</a></div>
        <br /> */}
        <div>Best Regards,</div>
        <div>Team Credfin</div>
     
    </div>
)

export default DefaultNautification