import React, { useEffect, useState } from "react";
import { bulkDeactivateNachDrp, getDrpRegisteredNach } from "../AdminActionCreator.component";
import { Pagination } from "@mui/material";
import Loader from '../../../presentationals/Loader/layerLoader.component'
import Popup from '../../../presentationals/Popup/Popup.component'


const BulkDeactivateDrpr = ({ allStatus }) => {
    const [loanStatus, setLoanStatus] = useState('109');
    const [pageNo, setPageNo] = useState(1);
    const [allUserDetail, setAllUserDetail] = useState([]);
    const [dataToSend, setDataToSend] = useState([]);
    const [loader, setloader] = useState(1);
    const [loaderState, setloaderState] = useState(false);
    const [totalPages, settotalPages] = useState(0);
    const [popupState, setPopupState] = useState(false);
    const [popupStatus, setpopupStatus] = useState('');
    const [paymentData, setPaymentData] = useState('');



    useEffect(() => {
        getData(loanStatus, pageNo);
    }, [loanStatus, pageNo]);

    const getData = (loanStatus, pageNo) => {
        setloaderState(true)
        getDrpRegisteredNach(loanStatus, pageNo, callBack => {
            setAllUserDetail(callBack.data);
            settotalPages(callBack.count);
            setDataToSend([]);
            setloaderState(false)
        });
    };

    const changeHandler = (e) => {
        const newLoanStatus = e.target.value;
        setLoanStatus(newLoanStatus);
    };

    const selectAll = (e) => {
        if (e.target.checked) {
            const alluserIds = allUserDetail.map(dataNew => dataNew.userId);
            setDataToSend(alluserIds);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = true;
            });
        } else {
            setDataToSend([]);
            allUserDetail.forEach((_, i) => {
                document.getElementById('disburseIcici' + i).checked = false;
            });
        }
    };

    const changeHandlerCheckbox = (e, data) => {
        if (e.target.checked) {
            setDataToSend(prev => [...prev, data.userId]);
        } else {
            setDataToSend(prev => prev.filter(id => id !== data.userId));
        }
    };

    const submit = () => {
        console.log(dataToSend);
        if (dataToSend.length > 0) {
            setloaderState(true)
            bulkDeactivateNachDrp(dataToSend, callback => {
                // if (callback === 'success') {
                setloaderState(false)
                setPaymentData(JSON.parse(callback))
                setPopupState(true)
                setpopupStatus('Done Successfully!')
                removerpopup()
                // } else {
                //     setloaderState(false)

                //     setPopupState(true)
                //     setpopupStatus('please try again later!')
                //     removerpopup()
                // }
            })
        } else {
            setPopupState(true)
            setpopupStatus('Please select application Id')
            removerpopup()
        }
    };
    const removerpopup = () => {
        setTimeout(() => {
            closePopup()
        }, 5000);
    }
    const handlePageChange = (direction) => {
        if (direction === 'prev' && pageNo > 1) {
            setPageNo(prev => prev - 1);
        } else if (direction === 'next' && pageNo < totalPages) {
            setPageNo(prev => prev + 1);
        }
    };
    const handleChangeNewPagination = (e, value) => {
        console.log(value)
        setPageNo(value)
        getData(loanStatus, value);

    }
    const closePopup = () => {
        setPopupState(false)
    }
    return (
        <div className="container-fluid">
            <h3><b>Bulk Deactivate Nach (Arham)</b></h3>
            {loaderState ? <Loader /> : ""}
            {popupState == true ?
                <Popup
                    closePopup={closePopup}
                    popupStatus={popupStatus}
                />
                : ""}
            {!loaderState ?
                <>
                    <div className="row " style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <div className="col-sm-4 col-xs-12">

                        </div>
                        <div className="col-sm-4 col-xs-12">
                            <label>Loan Status</label>
                            <select value={loanStatus} className="form-control" onChange={(e) => changeHandler(e)}>
                              
<option value={''}>Select Status</option>
                                {allStatus && allStatus.map((data, i) => (
                                    data.userStatus ?
                                        <>
                                            {data.statusId === '102' || data.statusId === '101' || data.statusId === '103' || data.statusId === '117' || data.statusId === '105'||data.statusId==='109' ?
                                                <option key={i} value={data.statusId}>{data.adminStatus}</option>
                                                : ""}

                                        </>
                                        : ""
                                ))}
                            </select>
                            <div style={{ marginTop: '12px', marginBottom: '5px' }}>
                                <input type="checkbox" onChange={selectAll} /> Select All   <button style={{ float: 'inline-end' }} className="btn btn-primary" onClick={submit}>Submit</button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <table className="table table-bordered">
                                <thead style={{backgroundColor:'#02534f'}}>
                                    <tr>
                                        <th>Select</th>
                                        <th>Application ID</th>
                                        <th>Status</th>
                                        <th>Source</th>
                                        <th>Mobile Number</th>
                                        {paymentData !== '' ?
                                            <th> Status</th>
                                            : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUserDetail && allUserDetail.map((data, i) => (
                                        <tr key={data.applicationId}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={'disburseIcici' + i}
                                                    onChange={(e) => changeHandlerCheckbox(e, data)}
                                                />
                                            </td>
                                            <td>{data.applicationId}</td>
                                            <td>{data.status}</td>
                                            <td>{data.mandateBy}</td>
                                            <td>{data.mobile}</td>
                                            {paymentData !== '' ? Object.keys(paymentData).includes(data.userId) ?
                                                <td>{(paymentData[data.userId]) !== null && (paymentData[data.userId]) !== undefined ? Object.values(paymentData[data.userId]) : "null"}</td>
                                                : "" : ""}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">

                        <div style={{ marginTop: '10px', float: 'right', marginBottom: '10px' }}>
                            <Pagination
                                // count={10}
                                count={Math.ceil(totalPages / 15 - 1)}
                                page={pageNo}
                                onChange={handleChangeNewPagination}
                                color="primary" />
                        </div>
                    </div>
                </>
                : ""}
        </div>
    );
};

export default BulkDeactivateDrpr;
