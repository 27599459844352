import React, { useState } from "react";
import Listing from "./listCategory";
import Pointers from "./pointer";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Category = ({pageViewHandler}) => {
    const [page, setpage] = useState('category')
    return (
        <div className="container-fluid" style={{ marginTop: '-10px' }}>
            <div className="">
                <div className="row " >
                    <div className="col-sm-6 col-xs-6" onClick={() => setpage('category')} style={{ borderBottom: page === 'configuration' ? '2px solid #006c67' : '', background: page === 'category' ? '#006c67' : '#DBE9FF', padding: page === 'category' ? '13px' : '12.2px', textAlign: 'center', color: page === 'category' ? '#fff' : '#000', cursor: 'pointer' }}>
                        <i class="fa fa-list" aria-hidden="true"></i>&nbsp; Category

                    </div>
                    <div className="col-sm-6 col-xs-6" onClick={() => setpage('pointer')} style={{ borderBottom: page === 'list' ? '2px solid #006c67' : '', background: page === 'pointer' ? '#006c67' : '#DBE9FF', padding: page === 'pointer' ? '13px' : '12.2px', textAlign: 'center', color: page === 'pointer' ? '#fff' : '#000', cursor: 'pointer' }}>
                        <i class="fa fa-list" aria-hidden="true"></i>&nbsp;  Pointers

                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 " style={{ marginTop: window.innerWidth > 1800 ? '0%' : '5%'}}>
                        <button style={{ color: '#223d64', fontWeight: '400', display: 'flex', border: '1px solid #267dff', borderRadius: '6px', padding: '8px 10px' }} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back</button>
                    </div>
                </div>
            </div>
            <div style={{marginTop:'10px'}}>
                {page === 'category' ?
                    <Listing />
                    : ""}
                {page === 'pointer' ?
                    <Pointers />
                    : ""}
            </div>
        </div>
    )
}
export default Category