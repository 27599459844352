import React from 'react';
import DisburseData from '../../../containers/Admin/CustomerPages/Disburse.popup';
import DisburseImage from '../../../../images/sampleapprovetodisburse.png'
import { all } from 'q';

const ApproveToDisburse = ({ handleForce, saveCsvData, saveApproveToDisbure, allData, tableMinPagination, tableMaxPagination, backpage, forwordpage, phocketTablePagination }) => (
    <div className="container-fluid">
        <div className="max-width-500px">
            <div className="row">
                <h3 className="text-center blue-text"> Bulk Approve To Disburse</h3>
            </div>
            <div className="row form-group">
                <div className="col-xs-12 display-flex">
                    <input type="file" name="fileToUpload" onChange={(e) => handleForce(e)} />
                    {/* <CSVReader
                        cssClass="react-csv-input"
                        onFileLoaded={handleForce}
                    /> */}
                    <button className="profile-save-btn margin-0" onClick={(e) => saveCsvData()}><span className="" />READ CSV</button>
                </div>
            </div>
        </div>
        <div className="row" style={{marginTop:'15px'}}>
            <div className="col-sm-7">
                <div className="overflow-x-auto">
                    <table className="phocket-table">
                        <thead>
                            <tr>
                                <th>Application Id</th>
                                <th>Disburse Date</th>
                                <th>Approve Amount</th>
                                <th>Email Id</th>
                                <th>Mail Send</th>
                                <th>NBFC</th>
                                <th>Save</th>
                            </tr>
                        </thead>
                        <tbody>

                            {allData != null && allData.length > 0 ? allData.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{data.applicationId}</td>
                                        <td>{data.disburseDate}</td>
                                        <td>{data.approveAmount}</td>
                                        <td>{data.emailId}</td>
                                        <td>{data != null && data.mailSend != null ? data.mailSend == true ? "Yes" : data.mailSend == false ? "No" : "No" : ""}</td>
                                        <td>{data.nbfcName}</td>
                                        <td><button className="fa fa-save" onClick={e => saveApproveToDisbure(data)}></button></td>
                                    </tr>
                                )
                            }) : ""}
                        </tbody>
                    </table>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" onClick={e => phocketTablePagination(allData, 'back')}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-4 col-xs-12  phoc" >{allData.length} of  {(tableMinPagination + 1) + ' - ' + (tableMaxPagination)}</div>
                        <div className="col-sm-4 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" onClick={e => phocketTablePagination(allData,'forword')}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
            </div>
            <div className="col-sm-5">
                <h3 className="text-center">Your CSV file must be in this format.</h3>
                <img className="center-block" src={DisburseImage} width="100%" />
            </div>
        </div>
    </div >
)

export default ApproveToDisburse;
