import React from 'react'
// import phocketlogo from '../../../images/phocket-logo.png'
import credfinlogo from '../../../images/credfinlogo.png'
// import Captcha from "react-numeric-captcha";


const CRMLoginP = ({captchaText,refresh, CRMCaptcha, loginHandler, CRMUser, CRMPassword, loginWarningMsg, captchaSuccess,
    captchaChange }) => (
    <div className="full-height" onKeyDown={e => loginHandler('enter', e)}>
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <div className="login100-pic js-tilt" data-tilt>
                        <img src={credfinlogo}  ></img>
                    </div>

                    <div className="login100-form">
                        <span className="login100-form-title">
                         Admin Login
                        </span>
                        <div className="font-12px height-25px validation-color">
                            {loginWarningMsg}
                        </div>
                        <div className="wrap-input100 " >
                            <input type="email" placeholder="email" className="input100" onChange={(e) => CRMUser(e)} />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </span>
                        </div>

                        <div className="wrap-input100 ">
                            <input className="input100" autoComplete="off" type="password" placeholder="Password" onChange={(e) => CRMPassword(e)} />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                                <i className="fa fa-lock" aria-hidden="true"></i>
                            </span>
                            
                        </div>
                        {captchaText != null && captchaText !=''?
                         <div>
                        <div className='row captcha-new'>
                            <span >{captchaText.captcha} &nbsp; </span>
                        </div>
                        <button className='captcha-btn' onClick={refresh}>
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        </button>
                       </div>
                        :""}
                        <div className="wrap-input100 " >
                            <input type="text" placeholder="Captcha" className="input100" onChange={(e) => CRMCaptcha(e)} />
                            <span className="focus-input100"></span>
                            <span className="symbol-input100">
                            <i className="fa fa-shield" aria-hidden="true"></i>
                            </span>
                        </div>
                        {/* <div className="row">
                                <Captcha 
                                    onChange={e => captchaChange(e)}
                                />
                            </div> */}
                        <div className="container-login100-form-btn">
                            <button className="login100-form-btn" onClick={e => loginHandler('click', e)} >
                                Login
						</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
)

export default CRMLoginP;