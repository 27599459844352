import React, { useState, useEffect } from "react";
import { genderWiseCountApi } from "../maketingComponents/actioncreator";
import { firstDateOfMonth, formatDate, lastDateOfMonth, monthStartDate, reverseFormat, subtractOneDay, todayDate } from "../maketingComponents/utils/utils";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";

const GenderStats = ({ admin }) => {
    const [nameOpportunity, setNameopportunity] = useState([])
    const [dataOpportunity, setDataopportunity] = useState([])

    const [payload, setPayload] = useState({
        fromDate: monthStartDate(),
        toDate: todayDate(),
        minDate: monthStartDate(),
    });
    const [rejectReasonData, setRejectReasonData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [optionsData, setOptionsData] = useState('');
    const [today, setToday] = useState(todayDate())

    useEffect(() => {
        getdataapi();
    }, []);

    const getdataapi = () => {
        genderWiseCountApi(
            payload,
            (callBack) => {

                setRejectReasonData(callBack);
                makegraphDataOpportunity(callBack)


            },
            (error) => {
                console.log(error);
            }
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newPayload = {
            ...payload,
            [name]: formatDate(value),
        };
        if (name === "fromDate") {
            newPayload.toDate =payload.toDate;
        } else {
            newPayload.fromDate = payload.fromDate;
        }
        setPayload(newPayload);
    };

    const prepareChartData = (data) => {

        const categories = Object.values(data.xaxis.categories);
        const seriesData = Object.values(data.xaxis.categories);
        const options = {
            series: [{
                data: seriesData
            }],
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories
            }
        };
        setOptionsData(options);
        console.log(options)
    };


    const makegraphDataOpportunity = (To) => {
        let name = [];
        let data = [];

        for (const item of To) {
            const { count, gender, } = item;

            name.push(gender);

            data.push(count);
        }

        setNameopportunity(name);
        setDataopportunity(data);

    }
   
    const options={
        annotations: {
            yaxis: [
              {
                y: 8800,
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  style: {
                    color: '#fff',
                    background: '#00E396'
                  },
                  text: 'Y-axis annotation on 8800'
                }
              }
            ]
          }
,          
        series: Object.values(dataOpportunity),
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: nameOpportunity,
        theme: {
            monochrome: {
                enabled: true,

            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5

                },

            }
        },
    
        fill: {
            colors: ['#4B49AC', '#7DA0FA', '#F3797E'],
            opacity: 0.9,
            type: 'solid',
            gradient: {
                shade: 'dark',
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: []
            },
        },
        dataLabels: {
            formatter(val, opts) {
                console.log(val)
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + '%'];
            }
        },
        legend: {
            markers: {
                fillColors: ['#4B49AC', '#7DA0FA', '#F3797E'],
            },
            show: true,
            formatter: function (seriesName, opts) {
                const count = Object.values(dataOpportunity)[opts.seriesIndex];
                // return seriesName + ': ' + count;
                const color = opts.w.globals.fill.colors[opts.seriesIndex];
                return `<span style="color: ${color};">${seriesName}</span>: ${count}`;
            }
    
        }
    }

    return (
        <div>
            <div className="col-sm-12 col-xs-12 shadow" style={{ marginBottom: '20px', border: '1px solid rgba(0, 0, 0, 0.25)', borderRadius: '10px' ,minHeight:'600px'}}>
                <div className="bg-white p-4 " style={{ padding: '15px' }}>
                    <h5 style={{ color: '#1d3557', fontWeight: '700' }}> <strong style={{ paddingTop: '10px' }}>Gender Stats</strong></h5>
                    <div className="row">
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                            From
                            <input
                                type="date"
                                id="startDate"
                                placeholder="DD/MM/YY"
                                name="fromDate"
                                value={reverseFormat(payload.fromDate)}
                                onChange={handleChange}
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>

                        <div className="col-sm-2 col-xs-12">
                            <div className="filterHeaders" style={{ marginTop: "4px" }}>
                                to
                            </div>

                            <input
                                type="date"
                                id="endDate"
                                name="toDate"
                                max={reverseFormat(subtractOneDay(today))}
                                value={reverseFormat(subtractOneDay(payload.toDate))}
                                onChange={handleChange}
                                placeholder="DD/MM/YY"
                                className=" form-control"
                                style={{
                                    border: "1px solid gray",
                                    borderRadius: "6px",
                                    padding: "10px",
                                }}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <button
                                className="btn btn-primary mt-4 "
                                style={{
                                    color: "#006c67",
                                    borderColor: "#006c67",
                                    backgroundColor: "white",
                                    marginTop: "23px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                                onClick={getdataapi}
                            >
                                Apply Filter
                            </button>
                        </div>
                    </div>


                    <hr />
                    <div className="row">
                        <div className="col-sm-6 col-xs-12 other-table">
                            <div className='marketing-table' style={{ fontSize: '12px',width:'90%',marginRight:'auto' }} >
                                <table>
                                    <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                        <tr className="thead-class">
                                            <th style={{ width: '20%', }} className="">
                                                <b>Gender</b>
                                            </th>
                                            <th style={{ width: '20%' }} className="">
                                                <b>Counts</b>
                                            </th>

                                        </tr>
                                    </thead>
                                    {console.log(dataOpportunity)}
                                    {rejectReasonData.length > 0 ?
                                        <tbody style={{ height: '650px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            {rejectReasonData.map((data1, i) => {

                                                return (
                                                    <tr style={{ borderBottom: "none", fontWeight: '400' }} key={i}>
                                                        <td style={{ width: '20%' }} scope="col">
                                                            {data1.gender}
                                                        </td>
                                                        <td style={{ width: '20%' }} scope="col">
                                                            {data1.count}
                                                        </td>

                                                    </tr>
                                                )

                                            })}
                                            <tr className="thead-class" style={{ fontWeight: 'bold', background: '#B9D8F973' }}>
                                                <td style={{ borderBottomLeftRadius: '10px',width:'50%' }}><b>Total</b></td>
                                                <td style={{width:'50%'}}>
                                                    <b>{rejectReasonData.reduce((total, data) => total + data.count, 0)}</b>
                                                </td>
                                                <td style={{ borderBottomRightRadius: '10px' }}></td>
                                            </tr>

                                        </tbody>
                                        :
                                        <tbody style={{ height: '650px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>

                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)', padding: '10px', borderRadius: '10px', marginTop: '21px' }}>
                                <h5><b>Gender Stats</b></h5>
                                <ReactApexChart options={options} series={options.series} type="pie" height={350} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenderStats;
