import React, { useEffect, useState } from "react";
import { getCategoryPointers, getCategoryList, saveCategory, savePointerMap } from "./actionCreatorScore";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const Pointers = () => {
    const [categoryData, setCategoryData] = useState('')
    const [name, setname] = useState('')
    const [loader, setLoader] = useState(false)
    const [categoryDataList, setCategoryDatalist] = useState('')
    const [jsonData, setjsonData] = useState({})


    useEffect(() => {
        getdata()
    }, [])
    const getdata = () => {
        setLoader(true)
        getCategoryList(callback => {
            if (callback) {
                setCategoryData(callback)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
        getCategoryPointers(callback => {
            if (callback) {
                setCategoryDatalist(callback)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
    }
    const saveCategoryApi = () => {
        setLoader(true)
        saveCategory(name, callback => {
            getdata()

        })
    }
    const changeHandler = (e) => {
        setname(e.target.value)
    }
    const pointerHandler = (e, type) => {
        let changeData = Object.assign({}, jsonData)
        if (type === 'categoryId') {
            changeData.categoryId = e.target.value
        }
        if (type === 'scoreType') {
            changeData.scoreType = e.target.value
        }
        if (type === 'score') {
            changeData.score = e.target.value
        }
        if (type === 'mandatory') {
            changeData.mandatory = e.target.value
        }
        setjsonData(changeData)
    }
    const saveData = () => {
        console.log(jsonData)
        if (jsonData.categoryId && jsonData.scoreType && jsonData.score && jsonData.mandatory) {
            savePointerMap(jsonData.categoryId, jsonData.scoreType, jsonData.score, jsonData.mandatory, callback => {
                console.log(callback)
            })
        }

    }
    return (

        <div className="">
            <div className="row" >
                <div className="col-sm-6 col-xs-12 ">
                    <div className="box-score">
                        <h3>  Category Pointers Listing</h3>

                        <div className="phocket-table-new">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Pointer</th>
                                        <th className="text-center">Category Name</th>
                                        <th className="text-center">Mandatory</th>
                                        <th className="text-center">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader === false && categoryDataList !== '' && categoryDataList.length > 0 && categoryDataList?.map((data, i) => {
                                        return (
                                            <tr>
                                                <td>{moment(data.addedOn).format('DD-MM-YYYY')}</td>
                                                <td>{data.scoreType}</td>
                                                <th className="text-center">{data.categoryName}</th>
                                                <th className="text-center"><input type="checkbox" readOnly checked={data.mandatoryScoring} /></th>
                                                <td className="text-center">{data.score}</td>
                                            </tr>
                                        )
                                    })}
                                    {loader ?
                                        <>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                            <tr>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>
                                                <td><Skeleton height={40} /></td>

                                            </tr>
                                        </>
                                        : ""}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xs-12 ">
                    <div className="box-score" style={{ overflow: 'hidden' }}>
                        <h3> Add Pointers</h3>
                        <label style={{ marginTop: '20px' }}>Select Category</label>
                        <select style={{ width: '70%' }} className="form-control" onChange={(e) => pointerHandler(e, 'categoryId')}>
                            <option >Select Value</option>
                            {categoryData && categoryData.map((data, i) => {
                                return (
                                    <option value={data.categoryId}>{data.name}</option>

                                )
                            })}
                        </select>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 col-xs-12">
                                <label>Particulars</label>
                                <input className="form-control" placeholder="Particulars" onChange={(e) => pointerHandler(e, 'scoreType')} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>score</label>
                                <input className="form-control" placeholder="Score" onChange={(e) => pointerHandler(e, 'score')} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <label>Madatory?</label>
                                <select className="form-control" onChange={(e) => pointerHandler(e, 'mandatory')} >
                                    <option>Select Value</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>

                                </select>
                            </div>

                        </div>
                        <div className="text-center" style={{ marginTop: '40px' }}>
                            <button className="btn btn-primary" onClick={() => saveData()}>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Pointers